import React from "react";
import Imgs2 from "../assets/Imgs2.png";
import Imgs from "../assets/Imgs.svg";
import Person from "../assets/person.png";
import Sent from "../assets/sent.png";
import Sent2 from "../assets/sent2.png";
import Ico from "../assets/ico.png";
import Emoji from "../assets/emoji.svg";
import Dir from "../assets/dir.svg";
import { Link } from "react-router-dom";

const list = [
  {
    img: Imgs2,
    name: "Helena Chavez",
    time: "11:52AM",
    title: "Leaving Fingerprints on the iPhone",
    msg: "How To Write Better Advertising Graphic designers always",
  },
  {
    img: Imgs,
    name: "Sallie Wade",
    time: "11:52AM",
    title: "Leaving Fingerprints on the iPhone",
    msg: "How To Write Better Advertising Graphic designers always",
  },
  {
    img: Imgs2,
    name: "Helena Chavez",
    time: "11:52AM",
    title: "Leaving Fingerprints on the iPhone",
    msg: "How To Write Better Advertising Graphic designers always",
  },
  {
    img: Imgs,
    name: "Sallie Wade",
    time: "11:52AM",
    title: "Leaving Fingerprints on the iPhone",
    msg: "How To Write Better Advertising Graphic designers always",
  },
  {
    img: Imgs2,
    name: "Helena Chavez",
    time: "11:52AM",
    title: "Leaving Fingerprints on the iPhone",
    msg: "How To Write Better Advertising Graphic designers always",
  },
  {
    img: Imgs,
    name: "Sallie Wade",
    time: "11:52AM",
    title: "Leaving Fingerprints on the iPhone",
    msg: "How To Write Better Advertising Graphic designers always",
  },
  {
    img: Imgs2,
    name: "Helena Chavez",
    time: "11:52AM",
    title: "Leaving Fingerprints on the iPhone",
    msg: "How To Write Better Advertising Graphic designers always",
  },
  {
    img: Imgs,
    name: "Sallie Wade",
    time: "11:52AM",
    title: "Leaving Fingerprints on the iPhone",
    msg: "How To Write Better Advertising Graphic designers always",
  },
  {
    img: Imgs2,
    name: "Helena Chavez",
    time: "11:52AM",
    title: "Leaving Fingerprints on the iPhone",
    msg: "How To Write Better Advertising Graphic designers always",
  },
];

export default function Chat() {
  return (
    <>
      <div className="flex gap-5 w-[95%] lg:w-[90%] mx-auto pt-36 pb-5 lg:py-5 xl:py-10">
        <div className="w-full lg:hidden block lg:w-[40%] xl:w-[30%] h-[100vh] overflow-y-scroll">
          {list.map((item, index) => (
         
         <Link to="/chatscreen"
              key={index}
              className=" flex  gap-3 bg-white rounded-lg p-3 mb-3"
            >

              <img
                src={item.img}
                alt=""
                className="self-center lg:self-start w-[60px] lg:w-[32px] h-[60px] lg:h-[32px]"
              />
              <div>
                <div className="flex justify-between">
                  <p className="font-[500] font-RobotoFlexRegular text-[10px] lg:text-[12px] text-[#5F646D]">
                    {item.name}
                  </p>
                  <p className="hidden lg:block font-[500] font-RobotoFlexRegular text-[12px] text-[#5F646D]">
                    {item.time}
                  </p>
                </div>

                <div className="">
                  <p className="mt-1 font-[700] font-RobotoFlexRegular text-[10px] xl:text-[14px] text-black">
                    {item.title}
                  </p>
                  <p className="mt-1 font-[400] font-RobotoFlexRegular text-[10px] xl:text-[14px] text-[#5F646D]">
                    {item.msg}
                  </p>
                </div>
              </div>

              <p className="block lg:hidden ml-auto font-[500] font-RobotoFlexRegular text-[10px] lg:text-[12px] text-[#5F646D]">
                {item.time}
              </p>
              </Link>
          ))}
        </div>
        <div className="w-full lg:block hidden lg:w-[40%] xl:w-[30%] h-[100vh] overflow-y-scroll">
          {list.map((item, index) => (
         
         <div 
              key={index}
              className=" flex  gap-3 bg-white rounded-lg p-3 mb-3"
            >

              <img
                src={item.img}
                alt=""
                className="self-center lg:self-start w-[60px] lg:w-[32px] h-[60px] lg:h-[32px]"
              />
              <div>
                <div className="flex justify-between">
                  <p className="font-[500] font-RobotoFlexRegular text-[10px] lg:text-[12px] text-[#5F646D]">
                    {item.name}
                  </p>
                  <p className="hidden lg:block font-[500] font-RobotoFlexRegular text-[12px] text-[#5F646D]">
                    {item.time}
                  </p>
                </div>

                <div className="">
                  <p className="mt-1 font-[700] font-RobotoFlexRegular text-[10px] xl:text-[14px] text-black">
                    {item.title}
                  </p>
                  <p className="mt-1 font-[400] font-RobotoFlexRegular text-[10px] xl:text-[14px] text-[#5F646D]">
                    {item.msg}
                  </p>
                </div>
              </div>

              <p className="block lg:hidden ml-auto font-[500] font-RobotoFlexRegular text-[10px] lg:text-[12px] text-[#5F646D]">
                {item.time}
              </p>
              </div>
          ))}
        </div>

        <div className="hidden lg:block w-[60%] xl:w-[70%]  bg-white rounded-lg p-5 h-[100vh] overflow-y-scroll mb-10">
          <button className="font-[700] text-[12px] text-[#0052FF] font-RobotoFlexRegular bg-[#CCDCFF] py-2 px-5 hover:shadow-md rounded-full block mx-auto">
            48 Messages
          </button>

          <div className="">
            <div className="flex justify-end gap-2">
              <div>
                <p className="text-right font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  <span className="font-[500]  text-[#5F646D]">8:20 PM</span>{" "}
                  Gabriela Pires
                </p>

                <p className="bg-[#0052FF] mt-1 rounded-tl-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-white">
                  Freelance Design Tricks
                </p>
              </div>
              <img src={Person} alt="" className="w-[34px] h-[34px]" />
            </div>

            <div className="flex justify-start gap-2 mt-5">
              <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

              <div>
                <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  Gabriela Pires{" "}
                  <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
                </p>

                <p className="bg-[#F5F5FA] mt-1 w-[60%] rounded-tr-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                  Some graphic designers always manage to produce
                </p>

                <div className="flex gap-4 mt-5">
                  <img src={Sent} alt="" className="w-[20%]" />
                  <img src={Sent2} alt="" className="w-[20%]" />
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-5">
              <div>
                <p className="text-right font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  <span className="font-[500]  text-[#5F646D]">8:20 PM</span>{" "}
                  Gabriela Pires
                </p>

                <p className="bg-[#0052FF] mt-1 rounded-tl-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-white">
                  Attending a trade show can be a very effective method
                </p>
              </div>
              <img src={Person} alt="" className="w-[34px] h-[34px]" />
            </div>

            <div className="flex justify-start gap-2 mt-5">
              <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

              <div>
                <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  Gabriela Pires{" "}
                  <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
                </p>

                <p className="bg-[#F5F5FA] mt-1 rounded-tr-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                  Advertising on a budget part
                </p>
              </div>
            </div>

            <div className="flex justify-start gap-2 mt-5">
              <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

              <div>
                <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  Gabriela Pires{" "}
                  <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
                </p>

                <p className="bg-[#F5F5FA]  mt-1 rounded-tr-full rounded-b-full py-4 px-3 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                  <img src={Ico} alt="" className="mx-auto" />
                </p>
              </div>
            </div>

            <div className="flex justify-end gap-2">
              <div>
                <p className="text-right font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  <span className="font-[500]  text-[#5F646D]">8:20 PM</span>{" "}
                  Gabriela Pires
                </p>

                <p className="bg-[#0052FF] mt-1 rounded-tl-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-white">
                  Freelance Design Tricks
                </p>
              </div>
              <img src={Person} alt="" className="w-[34px] h-[34px]" />
            </div>

            <div className="flex justify-start gap-2 mt-5">
              <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

              <div>
                <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  Gabriela Pires{" "}
                  <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
                </p>

                <p className="bg-[#F5F5FA] mt-1 w-[60%] rounded-tr-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                  Some graphic designers always manage to produce
                </p>

                <div className="flex gap-4 mt-5">
                  <img src={Sent} alt="" className="w-[20%]" />
                  <img src={Sent2} alt="" className="w-[20%]" />
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-5">
              <div>
                <p className="text-right font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  <span className="font-[500]  text-[#5F646D]">8:20 PM</span>{" "}
                  Gabriela Pires
                </p>

                <p className="bg-[#0052FF] mt-1 rounded-tl-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-white">
                  Attending a trade show can be a very effective method
                </p>
              </div>
              <img src={Person} alt="" className="w-[34px] h-[34px]" />
            </div>

            <div className="flex justify-start gap-2 mt-5">
              <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

              <div>
                <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  Gabriela Pires{" "}
                  <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
                </p>

                <p className="bg-[#F5F5FA] mt-1 rounded-tr-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                  Advertising on a budget part
                </p>
              </div>
            </div>

            <div className="flex justify-start gap-2 mt-5">
              <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

              <div>
                <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  Gabriela Pires{" "}
                  <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
                </p>

                <p className="bg-[#F5F5FA]  mt-1 rounded-tr-full rounded-b-full py-4 px-3 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                  <img src={Ico} alt="" className="mx-auto" />
                </p>
              </div>
            </div>
          </div>

          <div className="mt-14 relative">
            <input
              type="text"
              placeholder="Type to add something"
              className="bg-[#F5F5FA] py-5 px-14 outline-none rounded-lg w-full font-[400] text-[#5F646D] text-[14px] font-RobotoFlexRegular"
            />
            <div className="absolute top-3 left-3">
              <img src={Person} alt="" className="w-[34px] h-[34px]" />
            </div>

            <div className="flex gap-5 absolute top-4 right-4">
              <img src={Emoji} alt="" className="cursor-pointer" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="currentColor"
                className="w-4 h-4 self-center cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>

              <img src={Dir} alt="" className="cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
