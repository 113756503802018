import { useEffect, useState } from "react";
import { fetchTasksStatuses } from "../../helpers/api";

const Overview = () => {
  const [statuses, setStatuses] = useState<any[]>([]);
  const workspace = sessionStorage.getItem("workspace");

  // Fetch Logs from API
  useEffect(() => {
    fetchTasksStatuses().then((data) => {
      setStatuses(data || []);
    });
  }, [workspace]);

  return (
    <div className="relative max-lg:row-start-1">
      <div className="absolute inset-px bg-bgLight rounded-md dark:text-darkTextActiveColor border-[1px] dark:bg-bgDark dark:border-[#303030] border-[#CFDBD5]"></div>
      <div className="relative flex h-full flex-col rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)] overflow-hidden">
        <div className="px-8 pt-8 sm:px-10 sm:pt-10">
          <p className="mt-2 text-lg font-medium tracking-tight text-white max-lg:text-center">
            Overview
          </p>
          <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
            Track your task progress effortlessly. Helping you manage your team with
            clarity.
          </p>
        </div>
        <div className="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2 overflow-y-scroll mt-5">
          <ul
            role="list"
            className="divide-y divide-gray-100 dark:divide-gray-600 overflow-y-scroll h-full w-full"
          >
            {statuses?.map((status, index) => (
              <li className="py-4" key={index + status.status_name}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <div
                      className={`flex-shrink-0 w-2.5 h-2.5 bg-${status.color ? status.color : "blue"} rounded-full border border-1 border-gray-600`}
                      style={{ backgroundColor: status.color }}
                    ></div>
                    <p className="text-sm/6 text-gray-600">{status.status_name}</p>
                  </div>
                  <p className="text-sm/6 text-white">{status.task_count}</p>
                </div>
              </li>
            ))}
          </ul>
          {/* <img
            className="w-full max-lg:max-w-xs"
            src="https://tailwindui.com/plus/img/component-images/bento-03-performance.png"
            alt=""
          /> */}
        </div>
      </div>
      <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"></div>
    </div>
  );
};
export default Overview;
