import React from 'react';

const Avatar = ({ imageUrl, name } :any) => (
  <div className="relative h-10 w-10">
    <img
      className="h-full w-full rounded-full object-cover object-center"
      src={imageUrl}
      alt={name}
    />
    <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
  </div>
);

export default Avatar;