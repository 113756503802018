const PathConstants = {
  Login: "/",
  workspace: "/",
  Signup: "/signup",
  Dashboard: "/dashboard",
  Teams: "/teams",
  Projects: "/projects",
  projectPages: "/:slug/:page",
  CreateProject: "/create-project",
  Inbox: "/inbox",
  MyIssues: "/:slug/assigned",
};

export default PathConstants;
