const Card = ({
  classes,
  children,
}: {
  classes: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={`py-10 px-14 outline-none rounded-lg w-full font-[400] text-white text-[14px] font-RobotoFlexRegular my-2 border border-[#5f646d32] border-1 ${classes}`}
    >
      {children}
    </div>
  );
};

export default Card;
