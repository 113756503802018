import React, { createContext } from "react";
import useApi from "./useApi";

export interface WorkspaceData {
  "id": string,
  "slug": string,
  "name": string,
  "owner_id": string,
}

interface UserContextValue<T> {
  workspaces: Array<WorkspaceData>;
  loadingWorkspaces?: boolean;
}

const ConnectWorkspaceContext = createContext<UserContextValue<WorkspaceData>>({
  workspaces: [{
    "id": "",
    "slug": "",
    "name": "",
    "owner_id": "",
  }],
  loadingWorkspaces: false,
});

export function WordspaceProvider({ children }: { children: React.ReactNode }) {
  let { workspaces, loadingWorkspaces } = useApi();

  return (
    <ConnectWorkspaceContext.Provider
      value={{
        workspaces,
        loadingWorkspaces,
      }}
    >
      {children}
    </ConnectWorkspaceContext.Provider>
  );
}

export function useWorkspace() {
  return React.useContext(ConnectWorkspaceContext);
}
