import { useState } from "react";

export const ActionsList = ({ setTasks, tasks, taskId }: any) => {
  const [dorpdownMenu, setDropdownMenu] = useState(false);
  const task = tasks.filter((task: any) => task.task_id === taskId)[0];
  const handleDropdownMenu = (e: any) => {
    if (e.target.id === "menu-button") {
      setDropdownMenu(!dorpdownMenu);
    } else {
      setDropdownMenu(false);
    }
  };

  const handleTaskUpdate = (action: number) => {
    const updatedTasks = tasks.map((task: any) => {
      if (task.task_id === taskId) {
        return { ...task, priority: action };
      }
      return task;
    });
    setTasks(updatedTasks);
    setDropdownMenu(false);
  };

  const priorityList = [
    { name: "No Priority", icon: "priority-0.svg", id: 0 },
    { name: "Urgent", icon: "priority-1.svg", id: 1 },
    { name: "High", icon: "priority-2.svg", id: 2 },
    { name: "Medium", icon: "priority-3.svg", id: 3 },
    { name: "Low", icon: "priority-4.svg", id: 4 },
  ];

  return (
    <div className="relative inline-block text-left">
      <div className="flex items-center">
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-transparent px-3 py-0 text-[12px] font-semibold text-white shadow-sm ring-1 ring-inset ring-transparent"
          id="menu-button"
          onClick={(e) => handleDropdownMenu(e)}
          defaultValue={task?.priority}
        >
          <img
            src={`/assets/svgs/priority-${task?.priority}.svg`}
            className="w-5"
          />
          {
            priorityList.filter(
              (action: any) => action.id === task?.priority
            )[0]?.name
          }
        </button>
      </div>

      {dorpdownMenu && (
        <div
          className="w-[150px] absolute left-0 z-10 mt-2 origin-top-right rounded-md bg-darkBorderColor text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1" role="none">
            {priorityList.map((action, index) => (
              <div
                className="flex gap-2 items-center px-4 py-1 text-[12px]"
                role="menuitem"
                id={`menu-item-${index + 1}`}
                key={index}
                onClick={() => handleTaskUpdate(action.id)}
              >
                <img src={`/assets/svgs/${action.icon}`} className="w-5" />
                {action.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
