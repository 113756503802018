import React, { useState } from "react";

export default function Footer() {
  const currentTheme = localStorage.getItem("theme");
  const [theme, setTheme] = useState(currentTheme);
  const handleThemeChange = () => {
    if (theme === "light") {
      localStorage.setItem("theme", "dark");
      setTheme("dark");
    } else {
      localStorage.setItem("theme", "light");
      setTheme("light");
    }
    window.location.reload();
    return;
  };

  return (
    <>
      <div className="bg-transparent hidden lg:block fixed right-2 bottom-0 pt-2">
        <div className=" grid-cols-2 mx-auto pb-3 grid">
          <div className="flex gap-5 self-center">
            {/* <p className="flex gap-1 font-RobotoFlexRegular font-[700] text-[12px] text-black">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4 mt-[1px]"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM8.547 4.505a8.25 8.25 0 1011.672 8.214l-.46-.46a2.252 2.252 0 01-.422-.586l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.211.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.654-.261a2.25 2.25 0 01-1.384-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.279-2.132z"
                  clipRule="evenodd"
                />
              </svg>
              English
            </p>

            <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-[#5F646D] cursor-pointer">
              Privacy Policy
            </p>
            <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-[#5F646D] cursor-pointer">
              License
            </p>
            <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-[#5F646D] cursor-pointer">
              API
            </p> */}
          </div>
          {/* <div onClick={() => handleThemeChange()}>
            <div className="flex justify-around w-fit bg-white rounded-full px-2 border-[1px] border-gray-300 ml-auto">
              <div
                className={
                  theme === "light"
                    ? "bg-[#0052FF] text-white p-3 my-1 mx-1 rounded-full cursor-pointer"
                    : "hover:bg-[#0052FF] hover:text-white p-3 my-1 mx-1 rounded-full cursor-pointer"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z" />
                </svg>
              </div>

              <div
                className={
                  theme === "dark"
                    ? "bg-[#253760] text-white p-3 my-1 mx-1 rounded-full cursor-pointer"
                    : "hover:bg-[#253760] hover:text-white p-3 my-1 mx-1 rounded-full cursor-pointer"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
