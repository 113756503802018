import React from 'react';
import TableCell from './TableCell';
import Avatar from '../profile/Avatar';

interface ContactObject {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  company_id: number; // Assuming you have a way to resolve company names or IDs to company details
  lead_source: string;
  imageUrl: string;
}

const TableRow = ({ data, deleteAction } : { data:ContactObject, deleteAction:(id:number) =>  void; }) => (
  <tr>
    <TableCell>
      <div className="flex items-center gap-3 font-normal text-textColor dark:text-darkTextActiveColor">
      <Avatar name={`${data.first_name} ${data.last_name}`} imageUrl={data.imageUrl} />
        <div className="text-sm">
          <div className="font-medium text-textColor dark:text-darkTextActiveColor">{`${data.first_name} ${data.last_name}`}</div>
        </div>
      </div>
    </TableCell>
    <TableCell><div className="text-textColor dark:text-darkTextActiveColor">{data.email}</div></TableCell>
    <TableCell><div className="text-textColor dark:text-darkTextActiveColor">{data.phone_number}</div></TableCell>
    <TableCell><div className="text-textColor dark:text-darkTextActiveColor">{data.lead_source}</div></TableCell>
    <TableCell>
      <div className="ftext-textColor dark:text-darkTextActiveColor flex gap-2">
      {data.company_id}
      </div>
    </TableCell>
    <TableCell>
      <div className="flex justify-end gap-4">
        <div title="Delete" onClick={() => deleteAction(data.id)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
        <a href="#" title="Edit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
            />
          </svg>
        </a>
      </div>
    </TableCell>
  </tr>
);

const getTeamClassName = (team: any) => {
  switch (team) {
    case 'Design':
      return 'bg-blue-50 text-blue-600';
    case 'Product':
      return 'bg-indigo-50 text-indigo-600';
    case 'Develop':
      return 'bg-violet-50 text-violet-600';
    default:
      return '';
  }
};

export default TableRow;
