import React from 'react';

const TableHeader = () => (
  <thead className="dark:bg-dark bg-gray-50">
    <tr>
      <th scope="col" className="px-6 py-4 font-medium text-textColor dark:text-darkTextActiveColor">Name</th>
      <th scope="col" className="px-6 py-4 font-medium text-textColor dark:text-darkTextActiveColor">Email</th>
      <th scope="col" className="px-6 py-4 font-medium text-textColor dark:text-darkTextActiveColor">Phone Number</th>
      <th scope="col" className="px-6 py-4 font-medium text-textColor dark:text-darkTextActiveColor">Lead Source</th>
      <th scope="col" className="px-6 py-4 font-medium text-textColor dark:text-darkTextActiveColor">Company ID</th>
      <th scope="col" className="px-6 py-4 font-medium text-textColor dark:text-darkTextActiveColor"></th> {/* This can be used for actions like edit/delete */}
    </tr>
  </thead>
);

export default TableHeader;