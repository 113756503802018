import { useNavigate } from "react-router-dom";
import { useWorkspace } from "../context/workspace";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const { workspaces } = useWorkspace();
  const workspace = sessionStorage.getItem("workspace");

  const handleDashboard = () => {
    console.log("MADE IT HERE!!!")
    if (workspace) {
      const workspaceData = workspaces.find(
        (ws: any) => ws.id === workspace
      )
      if (workspaceData) {
        navigate(`/${workspaceData.slug}/overview`);
      }
    }
  };

  // Use tailwind to create a NICE NICE Nice 404 page
  return (
    <div className="flex justify-center">
      <div className="lg:w-[80%]">
        <div className="mt-40 mb-36 md:mb-28 md:w-[50%] w-[90%] mx-auto overflow-y-scroll">
          {/* <img
            src={Undraw}
            alt=""
            className="w-[50%] md:w-[40%] xl:w-[20%] mx-auto"
          /> */}
          <p className="text-textColor dark:text-darkTextActiveColor font-RobotoFlexRegular font-[800] text-[25px] md:text-[40px] text-center mt-5 md:mt-10">
            Not Found
          </p>
          <p className="text-textColor dark:text-darkTextActiveColor font-RobotoFlexRegular text-[16px] text-center mt-5 mb-8 mx-5">
            Looks like you've taken a wrong turn. The page you're looking for 
            might have been moved, deleted, or never existed. Please check the
            URL or go back to the <span className="text-blue/80 uppercase font-bold cursor-pointer" onClick={() => handleDashboard()}>dashboard</span> to find what you're looking for. If
            you believe this is an error, please contact support.
          </p>
        </div>
      </div>
    </div>
  );
}