export const ProfileImage = ({
  user,
  small,
  pill,
  show_name,
  classes,
  onClick,
}: {
  user?: any;
  pill?: boolean;
  small: boolean;
  show_name?: boolean;
  classes?: string;
  onClick?: () => void;
}) => {
  return (
    <div onClick={onClick} className={show_name ? `flex gap-5` : `block  w-auto h-auto`}>
      <div
        style={{
          backgroundColor: `${
            user?.profile_color
              ? `#${user?.profile_color?.split("#").join("")}`
              : "#000"
          }`,
        }}
        className={
          small
            ? `${classes} ${pill ? "text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-0 px-2 rounded-full border-[1px] border-white/50" : "text-xs w-[20px] h-[20px] mr-[-10px] p-[2px] border border-[#CFDBD5] rounded-full flex justify-center items-center text-white text-[10px] font-bold"}`
            : `${classes} ${pill ? "text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-full" : "w-[50px] h-[50px] py-2 cursor-pointer rounded-full flex justify-center center text-white text-[23px] font-bold"}`
        }
      >
        {user?.full_name || user?.name
          ? (() => {
              const names = (user?.full_name || user?.name).split(" ");
              if (names.length === 1) {
                // If there's only one name, take the first two letters
                return pill ?  names : names[0].substring(0, 2).toUpperCase();
              } else {
                // If there are multiple names, take the first letter of each
                return names.map((name: string) => name[0]).join("").toUpperCase();
              }
            })()
          : ""}
      </div>
      <span hidden={!show_name}>{user?.full_name || user?.name}</span>
    </div>
  );
};
