import Sidebar from "../components/sidebar/sidebar";
import { useState } from "react";
import sort from "../assets/sort.png";
import Undraw from "../assets/undraw_projections_re_ulc6.png";
import Plus from "../assets/plus.svg";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
// import BottomBar from "../components/bottom-bar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Menubar from "../components/menubar";

const MyIssuesEmpty = () => {
  const location = useLocation();
  const currentTab = location.state?.activeTab;

  const [activeTab, setActiveTab] = useState(currentTab || 1);
  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  let navigate = useNavigate();

  const handleNavigate = (link: string, tab: number): void => {
    navigate(link, { state: { activeTab: tab } });
  };

  return (
    <main className="flex">
      <div className="lg:w-[80%] w-full">
        <Menubar user={undefined} />
        <div className="project-content  w-[90%] mx-auto">
          {/* Tabs Content */}
          <div className="grid  mb-2 grid-cols-1 overflow-y-scroll ">
            {activeTab === 1 && (
              <div className="mt-20 mb-20 md:mb-28 w-[90%] md:w-full mx-auto overflow-y-scroll">
                <img
                  src={Undraw}
                  alt=""
                  className="w-[60%] md:w-[40%] xl:w-[20%] mx-auto"
                />
                <p className="font-RobotoFlexRegular font-[800] text-[25px] md:text-[40px] text-black text-center mt-5 md:mt-10">
                  No tasks found?
                </p>
                <p className="font-RobotoFlexRegular font-[400] text-[12px] md:text-[15px] text-[#5F646D] text-center mt-2">
                  Try to assign more tasks to your employees or
                  <br />
                  create a new project from scratch
                </p>

                <p
                  onClick={() => handleNavigate("/kanbandesk", 4)}
                  className="flex justify-center cursor-pointer gap-2 font-RobotoFlexRegular font-[700] text-[15px] text-[#0052FF] text-center mt-10"
                >
                  <img src={Plus} alt="" />
                  Create a new task
                </p>
              </div>
            )}

            {activeTab === 2 && (
              <div className="mt-20 mb-20 md:mb-28 w-[90%] md:w-full mx-auto overflow-y-scroll">
                <img
                  src={Undraw}
                  alt=""
                  className="w-[60%] md:w-[40%] xl:w-[20%] mx-auto"
                />
                <p className="font-RobotoFlexRegular font-[800] text-[25px] md:text-[40px] text-black text-center mt-5 md:mt-10">
                  No tasks found?
                </p>
                <p className="font-RobotoFlexRegular font-[400] text-[12px] md:text-[15px] text-[#5F646D] text-center mt-2">
                  Try to assign more tasks to your employees or
                  <br />
                  create a new project from scratch
                </p>

                <p
                  onClick={() => handleNavigate("/kanbandesk", 4)}
                  className="flex justify-center cursor-pointer gap-2 font-RobotoFlexRegular font-[700] text-[15px] text-[#0052FF] text-center mt-10"
                >
                  <img src={Plus} alt="" />
                  Create a new task
                </p>
              </div>
            )}

            {activeTab === 3 && (
              <div className="mt-20 mb-20 md:mb-28 w-[90%] md:w-full mx-auto overflow-y-scroll">
                <img
                  src={Undraw}
                  alt=""
                  className="w-[60%] md:w-[40%] xl:w-[20%] mx-auto"
                />
                <p className="font-RobotoFlexRegular font-[800] text-[25px] md:text-[40px] text-black text-center mt-5 md:mt-10">
                  No tasks found?
                </p>
                <p className="font-RobotoFlexRegular font-[400] text-[12px] md:text-[15px] text-[#5F646D] text-center mt-2">
                  Try to assign more tasks to your employees or
                  <br />
                  create a new project from scratch
                </p>

                <p
                  onClick={() => handleNavigate("/kanbandesk", 4)}
                  className="flex justify-center cursor-pointer gap-2 font-RobotoFlexRegular font-[700] text-[15px] text-[#0052FF] text-center mt-10"
                >
                  <img src={Plus} alt="" />
                  Create a new task
                </p>
              </div>
            )}

            {activeTab === 4 && (
              <div className="mt-20 mb-20 md:mb-28 w-[90%] md:w-full mx-auto overflow-y-scroll">
                <img
                  src={Undraw}
                  alt=""
                  className="w-[60%] md:w-[40%] xl:w-[20%] mx-auto"
                />
                <p className="font-RobotoFlexRegular font-[800] text-[25px] md:text-[40px] text-black text-center mt-5 md:mt-10">
                  No tasks found?
                </p>
                <p className="font-RobotoFlexRegular font-[400] text-[12px] md:text-[15px] text-[#5F646D] text-center mt-2">
                  Try to assign more tasks to your employees or
                  <br />
                  create a new project from scratch
                </p>

                <p
                  onClick={() => handleNavigate("/kanbandesk", 4)}
                  className="flex justify-center cursor-pointer gap-2 font-RobotoFlexRegular font-[700] text-[15px] text-[#0052FF] text-center mt-10"
                >
                  <img src={Plus} alt="" />
                  Create a new task
                </p>
              </div>
            )}

            {activeTab === 5 && (
              <div className="mt-20 mb-20 md:mb-28 w-[90%] md:w-full mx-auto overflow-y-scroll">
                <img
                  src={Undraw}
                  alt=""
                  className="w-[60%] md:w-[40%] xl:w-[20%] mx-auto"
                />
                <p className="font-RobotoFlexRegular font-[800] text-[25px] md:text-[40px] text-black text-center mt-5 md:mt-10">
                  No tasks found?
                </p>
                <p className="font-RobotoFlexRegular font-[400] text-[12px] md:text-[15px] text-[#5F646D] text-center mt-2">
                  Try to assign more tasks to your employees or
                  <br />
                  create a new project from scratch
                </p>

                <p
                  onClick={() => handleNavigate("/kanbandesk", 4)}
                  className="flex justify-center cursor-pointer gap-2 font-RobotoFlexRegular font-[700] text-[15px] text-[#0052FF] text-center mt-10"
                >
                  <img src={Plus} alt="" />
                  Create a new task
                </p>
              </div>
            )}

            {activeTab === 6 && (
              <div className="mt-20 mb-20 md:mb-28 w-[90%] md:w-full mx-auto overflow-y-scroll">
                <img
                  src={Undraw}
                  alt=""
                  className="w-[60%] md:w-[40%] xl:w-[20%] mx-auto"
                />
                <p className="font-RobotoFlexRegular font-[800] text-[25px] md:text-[40px] text-black text-center mt-5 md:mt-10">
                  No tasks found?
                </p>
                <p className="font-RobotoFlexRegular font-[400] text-[12px] md:text-[15px] text-[#5F646D] text-center mt-2">
                  Try to assign more tasks to your employees or
                  <br />
                  create a new project from scratch
                </p>

                <p
                  onClick={() => handleNavigate("/kanbandesk", 4)}
                  className="flex justify-center cursor-pointer gap-2 font-RobotoFlexRegular font-[700] text-[15px] text-[#0052FF] text-center mt-10"
                >
                  <img src={Plus} alt="" />
                  Create a new task
                </p>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
      {/* <BottomBar /> */}
    </main>
  );
};
export default MyIssuesEmpty;
