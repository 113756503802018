import { createClient } from "@supabase/supabase-js";
// Create a single supabase client for interacting with your database
// Ensure the environment variables are defined
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;
const SUPABASE_SERVICE_ROLE = process.env.REACT_APP_SUPABASE_SERVICE_ROLE;

if (!SUPABASE_URL || !SUPABASE_KEY || !SUPABASE_SERVICE_ROLE) {
  throw new Error("Missing Supabase environment variables");
}

export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);
export const admin_supabase = createClient(SUPABASE_URL, SUPABASE_SERVICE_ROLE, {
  auth: {
    autoRefreshToken: true,
    persistSession: false
  }
})
export const signOut = async () => {
  localStorage.removeItem("workspace");
  localStorage.removeItem("lastLocation");
  await supabase.auth.signOut();
  window.location.href = "/";
  return;
};
