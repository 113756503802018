import Navbar from "../components/navbar";
import Sidebar from "../components/sidebar/sidebar";
import Undraw from "../assets/undraw_writer_q06d.png";
import Plus from "../assets/plus.svg";
import Footer from "../components/footer";
// import BottomBar from "../components/bottom-bar";
import { Link } from "react-router-dom";

const Discussions = () => {
  return (
    <main className="flex">
      <div className="lg:w-[80%] ">
        <div className="mt-44 mb-36 md:mb-28 w-[90%] md:w-full mx-auto overflow-y-scroll">
          <img
            src={Undraw}
            alt=""
            className="w-[60%] md:w-[40%] xl:w-[20%] mx-auto"
          />
          <p className="font-RobotoFlexRegular font-[800] text-[25px] md:text-[40px] text-black text-center mt-5 md:mt-10">
          No discussions found?
          </p>
          <p className="font-RobotoFlexRegular font-[400] text-[12px] md:text-[15px] text-[#5F646D] text-center mt-2">
            Try to assign more tasks to your employees or
            <br />
            create a new project from scratch
          </p>

          {/* <Link to="/inbox">
            <p className="flex justify-center cursor-pointer gap-2 font-RobotoFlexRegular font-[700] text-[15px] text-[#0052FF] text-center mt-10">
              <img src={Plus} alt="" />
              Create new discussion
            </p>
          </Link> */}
        </div>

        <Footer />
      </div>
      {/* <BottomBar /> */}
    </main>
  );
};
export default Discussions;
