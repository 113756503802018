import { useState } from "react";
import styles from "./input.module.scss";

const Input = ({
  id,
  type,
  classes,
  placeholder,
  handleChange,
  predefinedText,
  urlSafe,
  disabled,
  isTextArea,
}: {
  id?: string;
  type: string;
  classes: string;
  placeholder: string;
  handleChange?: (value: string) => void;
  predefinedText?: string;
  urlSafe?: boolean;
  disabled?: boolean;
  isTextArea?: boolean;
}) => {
  const [value, setValue] = useState("");

  const handleInputChange = (e: any) => {
    if (urlSafe) {
      // replace spaces with dashes and make sure it's lowercase for url safety
      const formattedValue = e.target.value
        .replace(/ /g, "-")
        .replace(/[^a-zA-Z0-9-]/g, "")
        .toLowerCase();
      setValue(formattedValue);
      handleChange && handleChange(e);
      return;
    }
    setValue(e.target.value);
    handleChange && handleChange(e);
    return;
  };

  return (
    <div className="relative">
      <div
        className="absolute left-5 top-5 text-black"
        style={{ pointerEvents: "none" }}
      >
        <span className="sc-pyfCe jjlfCl">{predefinedText}</span>
      </div>
      {isTextArea ? (
        <textarea
          disabled={disabled}
          id={id}
          className={`${styles.input} ${
            predefinedText && "pl-[5.1rem]"
          } bg-white border border-[#CFDBD5] text-[#000000] placeholder:text-gray-800 font-[500] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-5 outline-[#0052FF]  ${classes}`}
          value={value}
          placeholder={placeholder}
          onChange={handleInputChange}
          rows={5}
        />
      ) : (
        <input
          disabled={disabled}
          id={id}
          type={type}
          className={`${styles.input} ${
            predefinedText && "pl-[5.1rem]"
          } bg-white border border-[#CFDBD5] text-[#000000] placeholder:text-gray-800 font-[500] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-5 outline-[#0052FF]  ${classes}`}
          value={value}
          placeholder={placeholder}
          onChange={handleInputChange}
        />
      )}
    </div>
  );
};

export default Input;
