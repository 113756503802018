import { supabase } from "../helpers/supabase";
import { Workspace } from "./types";

export const createWorkspace = async (workspace: Workspace, navigate:any) => {
  const { data, error } = await supabase.from("projects").insert(workspace).select();
  if (error) {
    console.log("Error creating workspace: ", error);
    return;
  }
  // Workspace created successfully
  console.log("Workspace created successfully: ", data);

  // redirect to projects
  sessionStorage.setItem("workspace", data[0].id);
  navigate(`/${data[0].slug}/issues`);
  window.location.reload();
  return data;
}