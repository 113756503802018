/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from "../assets/logo.svg";
import imgright from "../assets/Group1.jpg";
import envelop from "../assets/Icon.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { ChangeEvent, ComponentElement, useEffect, useState } from "react";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { blue } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import Mlogo from "../assets/Hidden.svg";
import { supabase } from "../helpers/supabase";
import { generateProfileColor } from "../helpers/profileColors";

interface ValuesType {
  full_name: string;
  email: string;
  password: string;
  agree: Boolean;
}

const Signup = () => {
  const [changeType, setPasswordType] = useState(false);
  const LoginSchema = Yup.object().shape({
    full_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      // check minimum characters
      .min(8, "Password must have at least 8 characters")
      .required(),
  });

  return (
    <section className="">
      <div className="grid grid-cols-1 lg:grid-cols-2 ">
        <div className="bg block lg:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className="w-6 h-6 text-white  self-center mt-10 ml-5 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>

          <img src={Mlogo} alt="" className="mx-auto pt-10" />
        </div>

        <div className="my-auto rounded-t-3xl lg:rounded-t-none bg-light dark:bg-bgDark">
          <div className="lg:w-[80%] xl:w-[60%] w-[90%] mx-auto pt-0 pb-5 lg:py-10">
            <div className="col-span-2 sm:block hidden">
              <img src={logo} alt="" className="w-[15%] hidden lg:block" />
            </div>

            <div className="col-span-12 xl:py-5 lg:py-4 py-10">
              <h2 className="text-textColor dark:text-darkTextColor xl:text-[42px] lg:text-[36px] text-[35px] text-center lg:text-start  font-RobotoFlexRegular font-[800] mt-10">
                Sign up
              </h2>
              <p className=" text-[#5F646D] font-RobotoFlexRegular text-[15px] lg:text-[14px] py-2 text-center lg:text-start">
                Before we start, please create an account below.
              </p>
              <Formik
                initialValues={{
                  full_name: "",
                  email: "",
                  password: "",
                  agree: true,
                }}
                validationSchema={LoginSchema}
                onSubmit={async (values: ValuesType) => {
                  if (values.full_name && values.email && values.password) {
                    const { data: session, error } = await supabase.auth.signUp(
                      {
                        email: values.email,
                        password: values.password,
                        options: {
                          data: {
                            full_name: values.full_name,
                            color_code: generateProfileColor(),
                          },
                        },
                      }
                    );

                    if (error) {
                      alert(error.message);
                      return;
                    }
                    if (!session) {
                      alert("Please check your inbox for email verification!");
                      return;
                    }
                    if (session) {
                      alert("Account created successfully!");
                      return;
                    }
                  }
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange }) => (
                  <form
                    className="space-y-4 xl:py-10 py-4 md:space-y-6 mt-5 lg:mt-0"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex justify-between gap-5">
                      <div className="w-full">
                        <label
                          htmlFor="data.user"
                          className="block mb-2 text-sm font-bold font-RobotoFlexRegular text-textColor dark:text-darkTextColor"
                        >
                          Full Name
                        </label>
                        <div className="relative">
                          <input
                            type="full_name"
                            name="full_name"
                            id="full_name"
                            onChange={handleChange}
                            className=" bg-white border border-[#CFDBD5] text-[#000000] placeholder:text-gray-800 font-[500] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full  p-5  outline-[#0052FF] "
                            placeholder=""
                          />
                          {errors.full_name && touched && (
                            <div
                              id="feedback"
                              className="text-[12px] text-red-500	"
                            >
                              {errors.full_name}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-bold font-RobotoFlexRegular text-textColor dark:text-darkTextColor"
                      >
                        Email
                      </label>
                      <div className="relative">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          onChange={handleChange}
                          className=" bg-white border border-[#CFDBD5] text-[#000000] placeholder:text-gray-800 font-[500] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full  p-5  outline-[#0052FF] "
                          placeholder=""
                        />
                        {errors.email && touched && (
                          <div
                            id="feedback"
                            className="text-[12px] text-red-500	"
                          >
                            {errors.email}
                          </div>
                        )}
                        <img
                          src={envelop}
                          alt=""
                          className="absolute top-6 right-4"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block mb-2 text-sm font-bold font-RobotoFlexRegular text-textColor dark:text-darkTextColor"
                      >
                        Password
                      </label>
                      <div className="relative">
                        <input
                          type={changeType ? "text" : "password"}
                          name="password"
                          id="password"
                          onChange={handleChange}
                          placeholder=""
                          className=" bg-white border border-[#CFDBD5] text-[#000000] placeholder:text-gray-800 font-[500] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full  p-5  outline-[#0052FF] "
                        />
                        <div className="absolute top-5 right-4">
                          {changeType ? (
                            <AiFillEye
                              size={20}
                              color="#808080"
                              onClick={() => setPasswordType((pre) => !pre)}
                            />
                          ) : (
                            <AiFillEyeInvisible
                              size={20}
                              onClick={() => setPasswordType((pre) => !pre)}
                              color="#808080"
                            />
                          )}
                        </div>

                        {errors.password && touched && (
                          <div
                            id="feedback"
                            className="text-[12px] text-red-500	"
                          >
                            {errors.password}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex items-start hidden">
                      <div className="flex items-center h-5">
                        <Checkbox
                          id="agree"
                          aria-describedby="agree"
                          onChange={handleChange}
                          sx={{
                            color: blue,
                            "&.Mui-checked": {
                              color: blue,
                            },
                            "&:hover": {
                              padding: "0px",
                              backgroundColor: "transparent",
                            },
                            padding: "0px",
                            background: "transparent",
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="agree"
                          className="text-[#5F646D] font-bold"
                        >
                          I have read and agree to Terms of Service
                        </label>
                      </div>
                    </div>

                    <button
                      disabled={
                        !values.agree || !!errors.email || !!errors.password
                      }
                      type="submit"
                      className="w-full text-white font-RobotoFlexRegular bg-[#0052FF] hover:shadow-md py-4 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5  text-center disabled:bg-black disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Create account
                    </button>
                  </form>
                )}
              </Formik>
            </div>
            <p className="text-[#000000] dark:text-[#fff] font-RobotoFlexRegular font-[600] text-[14px] lg:text-left text-center">
              Already registered?{" "}
              <span className="text-[#0052FF] font-RobotoFlexRegular font-bold">
                <Link to="/">Sign in</Link>
              </span>
            </p>
          </div>
        </div>
        <div>
          <img
            src={imgright}
            className="lg:block hidden h-full w-full"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};
export default Signup;
