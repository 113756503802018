import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import { Fab, Input } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useDraggable } from "react-use-draggable-scroll";
import styles from "./kanban.module.scss";

import TaskModel from "../../components/modal/TaskModal";
import trash from "../../assets/trash.svg";
import moveLeft from "../../assets/moveLeft.svg";
import moveRight from "../../assets/moveRight.svg";
import Img_task from "../../assets/Img_task.png";
import option from "../../assets/option.svg";
import iconplus from "../../assets/icon-plus.png";
import imgAssigned from "../../assets/Assigned.png";
import Upload from "../../assets/Upload.png";
import Dir from "../../assets/dir.svg";
// import BottomBar from "../../components/bottom-bar";
import { useLocation } from "react-router-dom";
import { getUser } from "../../hooks/user";
import { supabase } from "../../helpers/supabase";
import { subscribeToChanges } from "../../helpers/subscriptions";
import { Task } from "../../api/types";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { api, handleTaskDelete } from "../../helpers/api";
import { Editor } from "../../components/tiptap/Editor";
import { generateProfileColor } from "../../helpers/profileColors";
import { ProfileImage } from "../../components/profile/profileImage";
import { KanbanColumn } from "./kanban_columns";
import { ActionsList } from "./kanban_actions";

const KanBanDesk = () => {
  const location = useLocation();
  const { user, projects, projectsLoading } = getUser();
  const projectSlug = location.pathname.split("/")[1];
  const project = projects?.find(
    (project: any) => project.slug === projectSlug
  );
  const project_id = projects?.find(
    (project: any) => project.slug === projectSlug
  )?.id;

  /* ======================== */
  /* ==== Handle States ===== */
  /* ======================== */
  const [taskStatuses, setTaskStatuses] = useState<any>(null);
  const [tasks, setTasks] = useState<any>([]);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(2);

  const [createColumnModal, setCreateColumnModal] = useState(false);
  const [columnName, setColumnName] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [currentTask, setCurrentTask] = useState<Task>();
  const [taskId, setTaskId] = useState<any>("");
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskStatusId, setTaskStatusId] = useState("");
  const [taskDueDate, setTaskDueDate] = useState("");
  const [taskAssignees, setTaskAssignees] = useState<any>([]);
  const [projectMembers, setProjectMembers] = useState<any[]>([]);

  /* ======================== */
  /* ==== Use Effects ======= */
  /* ======================== */
  // Add members to project
  useEffect(() => {
    const fetchMembers = async () => {
      const members: any[] = [];

      // add members to project
      await projects
        ?.find((project: any) => project.slug === projectSlug)
        ?.project_members?.forEach((member: any) => {
          members.push({
            id: member.user_id.id,
            name: member.user_id.full_name,
            full_name: member.user_id.full_name,
            profile_color: member.user_id.profile_color,
            assignee_type: "user",
          });
        });

      // add teams to project
      await projects
        ?.find((project: any) => project.slug === projectSlug)
        ?.teams?.forEach((team: any) => {
          members.push({
            id: team.id,
            name: team.name,
            full_name: team.name,
            profile_color: "#000000",
            assignee_type: "team",
          });
        });

      // add owner to members
      members.push({
        id: user.id,
        name: user.full_name,
        full_name: user.full_name,
        profile_color: user.profile_color,
        assignee_type: "user",
      });

      // Set State
      setProjectMembers(members);
    };

    if (projectSlug != null && projectSlug != undefined && !projectsLoading) {
      fetchMembers();
    }
  }, [projects]);

  // Refs
  const tasksRef = useRef(tasks);
  const projectMembersRef = useRef(projectMembers);
  const taskStatusesRef = useRef(taskStatuses);

  useEffect(() => {
    tasksRef.current = tasks;
  }, [tasks]);

  useEffect(() => {
    projectMembersRef.current = projectMembers;
  }, [projectMembers]);

  useEffect(() => {
    taskStatusesRef.current = taskStatuses;
  }, [taskStatuses]);

  // Fetch Task Statuses
  useEffect(() => {
    const getTaskStatuses = async () => {
      const { data: data, error } = await supabase
        .from("project_task_status")
        .select(`*`)
        .eq("project_id", project_id);
      if (error) {
        console.error("Error fetching user data: ", error);
      }
      if (data) {
        setTaskStatuses(data);
        setTasksLoading(false);
      }
      return;
    };

    if (projectSlug != null && projectSlug != undefined && !projectsLoading)
      getTaskStatuses();
  }, [projectSlug, !projectsLoading]);

  // Fetch Tasks
  useEffect(() => {
    const getTasks = async () => {
      const { data: data, error } = await supabase
        .from("project_tasks")
        .select(`*, project_task_assignees(*, assignee_id(*), team_id(*), user_id(*))`)
        .eq("project_id", project_id);
      if (error) {
        console.error("Error fetching user data: ", error);
      }
      if (data) {
        setTasks(data);
      }
      return;
    };

    if (projectSlug != null && projectSlug != undefined && !projectsLoading)
      getTasks();
  }, [projectSlug, !projectsLoading]);

  /* ======================== */
  /* ==== Handle Inputs ===== */
  /* ======================== */
  const handleInputChanges = (e: any) => {
    switch (e.target.id) {
      case "taskName":
        setTaskName(e.target.value);
        break;
      case "taskDescription":
        setTaskDescription(e.target.value);
        break;
      case "columnName":
        setColumnName(e.target.value);
        break;
    }
  };

  /* ======================== */
  /* ==== Task Update ===== */
  /* ======================== */
  const handleTaskUpdate = async () => {
    const { priority } = tasksRef.current.find(
      (task: any) => task.task_id === taskId
    );
    if (taskName === "") {
      alert("Please fill all fields");
      return;
    } else if (
      taskName === currentTask?.name &&
      taskDescription === currentTask?.content &&
      taskStatusId === currentTask?.status &&
      /* due_date === (currentTask?.due_date ? new Date(currentTask.due_date).toDateString() : "") && */
      priority === currentTask?.priority
    ) {
      closeModal();
      return;
    }

    const { error } = await supabase
      .from("project_tasks")
      .update({
        name: taskName,
        content: taskDescription,
        status: taskStatusId,
        /* due_date: taskDueDate, */
        priority: priority,
      })
      .eq("task_id", taskId);

    if (error) alert("Error Updating Task");
    if (!error) closeModal();
  };

  /* ======================== */
  /* ==== Task Creation ===== */
  /* ======================== */
  const handleTaskCreation = async () => {
    if (taskName === "" || taskStatusId === "") {
      alert("Please fill all fields");
      return;
    }

    const { error } = await supabase.from("project_tasks").insert([
      {
        name: taskName,
        content: taskDescription,
        project_id: project_id,
        //project_task_id: 1,
        status: taskStatusId,
        due_date: new Date(),
        created_by: user.id,
        order: 1,
      },
    ]);

    if (error) {
      console.error("Error fetching user data: ", error);
    }

    if (!error) {
      closeModal();
    }
  };

  /* ======================== */
  /* ==== Column Creation === */
  /* ======================== */
  const handleColumnCreation = async () => {
    if (columnName === "") {
      alert("Please fill all fields");
      return;
    }

    const { data, error } = await supabase.from("project_task_status").insert([
      {
        status_name: columnName,
        project_id: project_id,
        order: taskStatuses?.length + 1,
      },
    ]);
    if (error) {
      console.error("Error fetching user data: ", error);
    }
    if (data) {
      return;
    }
    closeModal();
  };

  /* ======================== */
  /* ==== Handle Modals ===== */
  /* ======================== */
  const openModal = (status: string) => {
    setIsModalOpen(true);
    setTaskStatusId(status);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCreateColumnModal(false);
    setIsEditOpen(false);
    setTaskId("");
    setTaskName("");
    setTaskDescription("");
    setTaskDueDate("");
    setTaskStatusId("");
    setColumnName("");
    setTaskAssignees([]);
  };

  const editModal = (task: Task) => {
    setIsEditOpen(true);
    setCurrentTask(task);
    setTaskId(task.task_id);
    setTaskName(task.name);
    setTaskDescription(task.content);
    setTaskStatusId(task.status);
    setTaskDueDate(new Date(task.due_date).toDateString());
    setTaskAssignees(task.project_task_assignees);
  };

  /* ======================== */
  /* ==== Realtime Events === */
  /* ======================== */
  // handle task changes
  const handleTaskChanges = (payload: any) => {
    switch (payload.eventType) {
      case "INSERT":
        if (payload.new.project_id === project_id) {
          setTasks([
            ...tasksRef.current,
            {
              ...payload.new,
              project_task_assignees: [],
            },
          ]);
        }
        break;
      case "UPDATE":
        if (payload.new.project_id === project_id) {
          setTasks((currentTasks: any) =>
            currentTasks.map((task: any) =>
              task.task_id === payload.new.task_id
                ? {
                    ...payload.new,
                    project_task_assignees: task.project_task_assignees,
                  }
                : task
            )
          );
        }
        break;
      case "DELETE":
        setTasks(
          tasksRef.current.filter(
            (task: any) => task.task_id !== payload.old.task_id
          )
        );
        break;
    }
  };

  // handle task status changes
  const handleTaskStatusChanges = (payload: any) => {
    switch (payload.eventType) {
      case "INSERT":
        if (payload.new.project_id === project_id) {
          setTaskStatuses([...taskStatusesRef.current, payload.new]);
        }
        break;
      case "UPDATE":
        if (payload.new.project_id === project_id) {
          // update status
          setTaskStatuses((prev: any) =>
            prev.map((status: any) =>
              status.status_id === payload.new.status_id
                ? {
                    ...payload.new,
                  }
                : status
            )
          );
        }
        break;
      case "DELETE":
        setTaskStatuses(
          taskStatusesRef.current.filter(
            (taskStatus: any) => taskStatus.status_id !== payload.old.status_id
          )
        );
        break;
    }
  };

  // handle assignee changes
  const handleAssigneeChanges = (payload: any) => {
    // get assignee for new and old
    switch (payload.eventType) {
      case "INSERT":
        const assignee = projectMembersRef.current.find(
          (member: any) => member.id === payload.newuser_id || payload.new.assignee_id
        );
        setTasks(
          tasksRef.current.map((task: any) =>
            task.task_id === payload.new.task_id
              ? {
                  ...task,
                  project_task_assignees: [
                    ...task.project_task_assignees,
                    {
                      assignee_id: { ...assignee.id },
                      assignee_type: payload.new.assignee_type,
                      assignment_id: payload.new.assignment_id,
                      task_id: payload.new.task_id,
                      team_id: payload.new.team_id,
                      user_id: payload.new.user_id,
                    },
                  ],
                }
              : task
          )
        );
        break;
      case "DELETE":
        setTasks(
          tasksRef.current.map((task: any) =>
            task.project_task_assignees.find(
              (t: any) => t.assignment_id !== payload.old.assignment_id
            )
              ? {
                  ...task,
                  project_task_assignees: task.project_task_assignees.filter(
                    (t: any) => t.assignment_id !== payload.old.assignment_id
                  ),
                }
              : task
          )
        );
        break;
    }
  };

  // subscribe to task changes in realtime
  useEffect(() => {
    subscribeToChanges({
      channelName: "project_tasks",
      eventHandler: handleTaskChanges,
    });
  }, [!projectsLoading, !tasksLoading]);

  // subscribe to task status changes in realtime
  useEffect(() => {
    subscribeToChanges({
      channelName: "project_task_status",
      eventHandler: handleTaskStatusChanges,
    });
  }, [!projectsLoading]);

  // subscribe to changes on assignees
  useEffect(() => {
    subscribeToChanges({
      channelName: "project_task_assignees",
      eventHandler: handleAssigneeChanges,
    });
  }, [!projectsLoading]);

  /* ======================== */
  /* ==== Drag and Drop ===== */
  /* ======================== */
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    // Make sure we have a valid destination/column
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    // Item being dragged
    const task = tasksRef.current.find(
      (task: any) => task.task_id === Number(draggableId)
    );

    // Update Task
    const updatedTask = {
      ...task,
      status: Number(destination.droppableId),
      order: Number(destination.index),
    };

    // Get tasks to update
    let tasksToUpdate = tasksRef.current
      ?.filter((task: any) => task.status === Number(destination.droppableId))
      ?.sort((a: any, b: any) => a?.order - b?.order);

    // Check if task is already in tasksToUpdate list
    if (
      tasksToUpdate?.some((task: any) => task.task_id === updatedTask.task_id)
    ) {
      tasksToUpdate = tasksToUpdate.filter(
        (task: any) => task.task_id !== updatedTask.task_id
      );
    }

    // Insert updatedTask at the new position
    tasksToUpdate.splice(destination.index, 0, updatedTask);

    // Update the order of tasks in tasksToUpdate list
    tasksToUpdate.forEach((task: any, index: number) => {
      task.order = index + 1;
    });

    // Sort tasksToUpdate
    tasksToUpdate.sort((a: any, b: any) => a?.order - b?.order);

    if (task.order !== undefined && task.order !== null) {
      setTasks((currentTasks: any) =>
        currentTasks.map((task: any) =>
          task.task_id === Number(draggableId)
            ? {
                ...task,
                status: Number(destination.droppableId),
                order: Number(destination.index),
              }
            : task
        )
      );

      // Save the new order to the database
      const updateTask = async () => {
        await api.updateTaskOrder({
          task_id: Number(draggableId),
          order: Number(destination.index) + 1,
          status_id: Number(destination.droppableId),
        });
        /* await api.bulkUpdateTasks({
          updatedTasks: tasksToUpdate,
        }); */
      };
      updateTask();
    }
  };

  const ref = useRef<any>(null);
  const { events } = useDraggable(ref);

  /* ======================== */
  /* ==== Task Delete ====== */
  /* ======================== */
  const deleteAlert = async ({ task_id }: { task_id: Number }) => {
    // Confirm delete
    if (window.confirm("Are you sure you want to delete this task?")) {
      // Remove task from tasks
      setTasks(
        tasksRef.current.filter((task: any) => task.task_id !== task_id)
      );

      // Delete task from database
      await handleTaskDelete({ task_id });
    }
  };

  return (
    <>
      <div
        className="project-content relative w-[100%] h-[100vh] sm:w-[100%] mx-auto pb-20"
        {...events}
        ref={ref}
      >
        <div className="grid mb-2 grid-cols-1 absolute left-0 right-0 h-[calc(100%-6rem)] lg:h-[calc(100%-4rem)]">
          {activeTab === 2 && (
            <div className="flex w-[100%] scrolling overflow-x-scroll">
              {/* ======================== */
              /* ==== Tasks Statuses ==== */
              /* ======================== */}
              <DragDropContext onDragEnd={onDragEnd}>
                {taskStatuses
                  ?.sort((a: any, b: any) => a.order - b.order)
                  ?.map((status: any, index: number) => {
                    const statusTasks = tasks?.filter(
                      (task: any) => task.status === status.status_id
                    );
                    return (
                      <div
                        key={status.status_id}
                        className="h-auto overflow-scroll flex-shrink-0 basis-[330px] dark:bg-dark m-1 px-2 rounded-lg border-t-[3px] border-borderColor dark:border-darkBorderColor"
                        style={{ borderColor: status.color }}
                      >
                        <div className="text-textColor dark:text-darkTextActiveColor cursor-pointer py-4 rounded-lg sticky top-0 bg-white dark:bg-dark z-10">
                          <div className="flex font-bold text-left items-center justify-between px-4">
                            {/*=========================*/}
                            {/*==== Column Creation ====*/}
                            {/*=========================*/}
                            <KanbanColumn
                              status={status}
                              statusTasks={statusTasks}
                              taskStatusesRef={taskStatusesRef}
                              setTaskStatuses={setTaskStatuses}
                              openModal={(status: string) => openModal(status)}
                            />
                          </div>
                        </div>

                        <Droppable droppableId={status.status_id.toString()}>
                          {(provided) => (
                            <ul
                              ref={provided.innerRef}
                              className="rounded-lg px-1 mt-5 w-[100%]"
                              {...provided.droppableProps}
                            >
                              {statusTasks
                                ?.sort((a: any, b: any) => a.order - b.order)
                                .filter(
                                  (task: any) =>
                                    task.status === status.status_id
                                )
                                ?.map((task: any, index: number) => {
                                  return (
                                    <Draggable
                                      key={task.task_id}
                                      draggableId={task.task_id.toString()}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <li
                                          key={task.task_id}
                                          ref={provided.innerRef}
                                          className="group relative bg-taskbgLight dark:bg-taskbgDark mb-2 rounded-lg items-end py-2 px-4 grid grid-cols-12 hover:shadow-md hover:cursor-pointer border-[1px] border-borderColor dark:border-[#51515129]"
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <div
                                            id={status.status_id}
                                            onClick={() => editModal(task)}
                                            className="pmtool_task h-full w-full bg-transparent absolute left-0"
                                          />
                                          <div className="col-span-10">
                                            <span className="text-[#888] pb-2 text-[12px] font-medium mb-4">
                                              #{task.project_task_id}{" "}
                                            </span>
                                            <h2 className="text-black dark:text-white pb-2 text-[14px] mb-4">
                                              {task.name}
                                            </h2>

                                            {/*==========================*/}
                                            {/*====== Delete Task ====== */}
                                            {/*==========================*/}
                                            <div
                                              className="absolute top-2 right-2 opacity-0 group-hover:opacity-50"
                                              onClick={() =>
                                                deleteAlert({
                                                  task_id: task.task_id,
                                                })
                                              }
                                            >
                                              <img
                                                src={trash}
                                                alt="delete task"
                                                className="w-4 opacity-50"
                                              />
                                            </div>

                                            {/*======================*/}
                                            {/*====== Actions ====== */}
                                            {/*======================*/}
                                            <div className="absolute bottom-2 left-2">
                                              <img
                                                src={`/assets/svgs/priority-${task.priority}.svg`}
                                                alt="delete task"
                                                className="w-4"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-span-2 flex text-right justify-end gap-1">
                                            {task.project_task_assignees?.map(
                                              (assignee: any) => {
                                                return (
                                                  <ProfileImage
                                                    user={
                                                      assignee.assignee_type ===
                                                      "team"
                                                        ? assignee.team_id
                                                        : assignee.user_id ?? assignee.assignee_id
                                                    }
                                                    small={true}
                                                    pill={true}
                                                  />
                                                );
                                              }
                                            )}
                                          </div>
                                        </li>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </div>
                    );
                  })}
              </DragDropContext>

              <div className="h-auto overflow-scroll flex-shrink-0 basis-[330px] dark:bg-dark m-1 px-2 rounded-lg border-[1px] border-borderColor dark:border-darkBorderColor">
                <div
                  onClick={() => setCreateColumnModal(true)}
                  className="w-full text-textColor dark:text-white dark:bg-bgDark cursor-pointer py-4 rounded-lg sticky top-0 z-10 my-2"
                >
                  <div className="flex gap-2 font-bold w-fit mx-auto text-center items-center text-[18px]">
                    +
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <BottomBar /> */}

      {/* ======================== */
      /* ====== Modals ========== */
      /* ======================== */}
      {/* Edit Task Modal */}
      <TaskModel isOpen={isEditOpen} onClose={() => handleTaskUpdate()}>
        <div className="pt-5 pb-2 px-5 max-h-[500px] overflow-y-scroll">
          <div className="grid grid-cols-1 py-4 ">
            <input
              className="text-[24px] text-white font-bold border-b-2 border-darkBorderColor outline-none focus:outline-none bg-transparent"
              type={""}
              placeholder={"Name"}
              onChange={(e) => handleInputChanges(e)}
              value={taskName}
              id="taskName"
            />
          </div>
          <div className="grid items-start sm:grid-cols-2 grid-cols-1 gap-5">
            <div>
              <h2 className="text-[12px] text-[#5F646D] font-medium">
                Assigned to
              </h2>
              <div
                className={`grid grid-rows-2 grid-flow-col gap-2 w-full py-2 cursor-pointer ${styles.assign}`}
              >
                {taskAssignees.map((assignee: any) => {
                  return (
                    <ProfileImage
                      user={
                        assignee.assignee_type === "team"
                          ? assignee.team_id
                          : assignee.user_id ?? assignee.assignee_id
                      }
                      pill={true}
                      small={false}
                      classes={`${styles.user} grid grid-rows-subgrid gap-4 row-span-2 text-center`}
                      onClick={() => {
                        setTaskAssignees(
                          taskAssignees.filter(
                            (t: any) =>
                              t.assignment_id !== assignee.assignment_id
                          )
                        );
                        setTasks(
                          tasks.map((task: any) =>
                            task.task_id === taskId
                              ? {
                                  ...task,
                                  project_task_assignees:
                                    task.project_task_assignees.filter(
                                      (t: any) =>
                                        t.assignment_id !== assignee.assignment_id
                                    ),
                                }
                              : task
                          )
                        );
                        api.removeMemberFromTask({
                          assignment_id: assignee.assignment_id,
                        });
                      }}
                    />
                  );
                })}
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      {taskAssignees.length < 3 && (
                        <AddIcon
                          className="flex justify-center justify-self-center bg-[#E9E9F2] hover:bg-[#c0c0cb] rounded-full cursor-pointer"
                          {...bindTrigger(popupState)}
                        />
                      )}
                      <Menu {...bindMenu(popupState)} className="w-full">
                        {projectMembers
                          .filter((member) => {
                            return tasks
                              ?.find((t: any) => t.task_id === taskId)
                              ?.project_task_assignees?.every((t: any) =>
                                t.assignee_type === "team"
                                  ? t.team_id.id !== member.id
                                  : t.user_id?.id !== member.id || t.assignee_id?.id !== member.id
                              );
                          })
                          ?.map((assignee: any) => {
                            return (
                              <MenuItem
                                onClick={async () => {
                                  setTasks(
                                    tasks.map((task: any) =>
                                      task.task_id === taskId
                                        ? {
                                            ...task,
                                            project_task_assignees: [
                                              ...task.project_task_assignees,
                                              {
                                                assignee_type: assignee.assignee_type,
                                                user_id: {
                                                  id: assignee.id,
                                                  name: assignee.name,
                                                  profile_color: assignee.profile_color,
                                                },
                                                team_id: {
                                                  id: assignee.id,
                                                  name: assignee.name,
                                                  profile_color: "#000000",
                                                  assignee_type: assignee.assignee_type,
                                                  user_id: assignee.id,
                                                },
                                              },
                                            ],
                                          }
                                        : task
                                    )
                                  );
                                  setTaskAssignees([
                                    ...taskAssignees,
                                    {
                                      task_id: taskId,
                                      assignee_type: assignee.assignee_type,
                                      user_id: {
                                        id: assignee.id,
                                        name: assignee.name,
                                        profile_color: assignee.profile_color,
                                      },
                                      team_id: {
                                        id: assignee.id,
                                        name: assignee.name,
                                        profile_color: "#000000",
                                        assignee_type: assignee.assignee_type,
                                        user_id: assignee.id,
                                      },
                                    },
                                  ]);
                                  await api.addMemberToTask({
                                    assignee_id: user.id,
                                    task_id: taskId,
                                    assignee_type: assignee.assignee_type,
                                    team_id: assignee.id,
                                    user_id: assignee.id,
                                  });
                                  popupState.close();
                                }}
                              >
                                <ProfileImage
                                  user={assignee}
                                  small={true}
                                  show_name={true}
                                />
                              </MenuItem>
                            );
                          })}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </div>
            </div>
            <div>
              <h2 className="text-[12px] text-[#5F646D] font-medium">
                Actions
              </h2>
              <ul className="flex gap-5 items-center py-4">
                <li className="w-[100%] cursor-pointer">
                  <ActionsList
                    setTasks={setTasks}
                    tasks={tasks}
                    taskId={taskId}
                  />
                </li>
                {/* <li className="w-[100%] cursor-pointer">
                  <input
                    type="date"
                    id="due_date"
                    name="due_date"
                    className="bg-[#F5F5FA]  px-4 py-2 rounded-full text-[#5F646D] text-center font-bold text-[14px] opacity-20"
                  />
                </li> */}
              </ul>
            </div>
          </div>
          <div className="border my-2 border-darkBorderColor"></div>

          <h2 className="text-[18px] py-2 text-white  font-bold">
            Description
          </h2>
          <Editor
            defaultContent={taskDescription}
            setTaskDescription={(content) => setTaskDescription(content)}
          />
        </div>
        {/* <div className="bg-[#F5F5FA] px-4 items-center  rounded-b-[20px] grid grid-cols-12 py-5">
          <div className="col-span-11">
            <input
              type="text"
              className=" bg-[#F5F5FA] w-full focus-visible:none focus:outline-none"
              placeholder="Type to add your comment - Disabled"
              disabled
            />
          </div>
        </div> */}
      </TaskModel>

      {/* Create Task Modal */}
      <TaskModel isOpen={isModalOpen} onClose={closeModal}>
        <div className="pt-5 pb-2 px-5">
          <div className="grid grid-cols-1 py-4">
            <input
              className="text-[24px] text-white bg-transparent font-bold border-b-2 border-borderColor dark:border-darkBorderColor outline-none focus:outline-none"
              type={""}
              placeholder={"Name"}
              onChange={(e) => handleInputChanges(e)}
              value={taskName}
              id="taskName"
            />
          </div>
          <div className="grid items-start sm:grid-cols-2 grid-cols-1 gap-5">
            <div>
              <h2 className="text-[12px] text-[#5F646D] font-medium">
                Assigned to
              </h2>
              <p className="text-[12px] text-[#9fa3ac]">
                Create Task then assign members
              </p>
            </div>
            <div>
              <h2 className="text-[12px] text-[#5F646D] font-medium">
                Due Date
              </h2>
              <ul className="flex gap-5  items-center py-4">
                <li className="w-[100%] cursor-pointer">
                  <input
                    type="date"
                    id="date_picker"
                    name="date_picker"
                    className="w-full bg-[#F5F5FA]  px-4 py-2 rounded-full text-[#5F646D] text-center font-bold text-[14px]"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="border my-2 border-borderColor dark:border-darkBorderColor"></div>

          <h2 className="text-[18px] py-2 text-white font-bold">Description</h2>
          <Editor
            defaultContent={taskDescription}
            setTaskDescription={(content) => setTaskDescription(content)}
          />
        </div>
        <div className="px-4 items-center  rounded-b-[20px] py-2">
          <button
            onClick={() => handleTaskCreation()}
            className="bg-blue rounded-md p-2 text-white font-bold text-sm w-full"
          >
            Create
          </button>
        </div>
      </TaskModel>

      {/* Create Column Modal */}
      <TaskModel isOpen={createColumnModal} onClose={() => closeModal()}>
        <div className=" pt-5 pb-2 px-5 ">
          <div className="grid grid-cols-1 py-4 ">
            <input
              className="text-[24px] text-white font-bold border-none outline-none focus:outline-none bg-transparent"
              type={""}
              placeholder={"Column Name"}
              onChange={(e) => handleInputChanges(e)}
              value={columnName}
              id="columnName"
            />
          </div>
          <div className="border my-2 border-[#CFDBD5]"></div>
        </div>
        <div className="bg-transparent px-4 items-center  rounded-b-[20px] py-2">
          <button
            onClick={() => handleColumnCreation()}
            className="bg-blue rounded-md p-2 text-white font-bold text-sm w-full"
          >
            Add Column
          </button>
        </div>
      </TaskModel>
    </>
  );
};
export default KanBanDesk;
