import React from "react";
import styles from "./Modal.module.scss";

export function Modal({ open, setOpen, title, children, classes }: { open: boolean, setOpen: any, title?: string, children: any, classes?: string}) {

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setOpen(false);
    }
  };

  return (
    <>
      { open && (
        <div
          className={`${styles.modalOverlay} bg-white bg-opacity-50 dark:bg-black dark:bg-opacity-50 backdrop-blur-sm`}
          /* onClick={handleOverlayClick} */>
          <div className={`${styles.modal} ${classes}`}>
            <div className="flex justify-between">
              <div className="flex">
                <p className="text-textColor dark:text-textColor text-[22px] xl:text-[14px] ml-2 self-center  pb-1 gap-3 font-RobotoFlexRegular font-bold">
                  {title}
                </p>
              </div>
              <button className="text-white" onClick={() => setOpen((prev: any) => !prev)}>&#x2715;</button>
            </div>
            <div className="p-4">
              <p className="text-textColor dark:text-textColor text-[12px] xl:text-[14px] ml-2 self-center  pb-1 gap-3 font-RobotoFlexRegular font-bold">
                {children}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
