const TaskModel = ({ isOpen, onClose, children }:any) => {
  const modalClass = isOpen ? 'block' : 'hidden';

  return (
    <div className={`h-full fixed  inset-0 ${modalClass} z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto`}>
      <div
        className="fixed inset-0 transition-opacity"
        onClick={onClose}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div className="h-full absolute right-0 top-0 bg-bgDark  overflow-y-scroll  w-[95%] md:w-[70%] lg:w-[50%] xl:w-[50%] shadow-lg z-20 border-l-2 border-borderColor dark:border-darkBorderColor">
        {children}
      </div>
    </div>
  );
};

export default TaskModel;
