import Sidebar from "../components/sidebar/sidebar";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { blue } from "@mui/material/colors";
import Mlogo from "../assets/Hidden.svg";
import imgmockup from "../assets/mockup.png";
import dropbox from "../assets/dropbox.png";
import slack from "../assets/slack.png";
import sketch from "../assets/sketch.png";
import shotoshot from "../assets/shotoshot.png";
import amazon from "../assets/amazon.png";
import report from "../assets/report.png";
import google from "../assets/google.png";
import nft from "../assets/nft.png";
import card from "../assets/card.png";
import sort from "../assets/sort.png";

import checkimg from "../assets/check_icon.svg";
import cheat from "../assets/cheat.svg";
import linkicon from "../assets/link-icon.svg";
import usergroup from "../assets/user-group.svg";
import users2 from "../assets/users-2.png";
import users3 from "../assets/users-3.png";
import users4 from "../assets/users-4.png";
import users5 from "../assets/users-5.png";
import users6 from "../assets/users-6.png";
import users7 from "../assets/users-7.png";
import Icons from "../assets/icons.svg";

import option from "../assets/option.svg";

import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import BottomBar from "../components/bottom-bar";
import { Link } from "react-router-dom";
import { supabase } from "../helpers/supabase";
import { getUser } from "../hooks/user";
import PathConstants from "../pathConstants";
interface projects {
  name: string;
  img: string;
  checks: string;
  chats: number;
  links: number;
  date: string;
  users: string;
}

const MyIssues = () => {
  const { user, projectsLoading } = getUser();
  const [tasks, setTasks] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  // Fetch Tasks
  // get each project slug from each task
  useEffect(() => {
    const getTasks = async () => {
      const { data: getTasks, error } = await supabase
        .from("project_tasks")
        .select(`*`)
        .eq("created_by", user.id);

      if (error) {
        console.error("Error fetching project tasks: ", error);
      }

      const projectIds = getTasks
        ? getTasks.map((task) => task.project_id)
        : [];

      const { data: project, error: projectsError } = await supabase
        .from("projects")
        .select(`slug, id`)
        .in("id", projectIds);

      // add slug to each task
      const _tasks = getTasks?.map((task) => {
        const projectSlug = project?.find(
          (project) => project.id === task.project_id
        )?.slug;
        return { ...task, slug: projectSlug };
      });

      if (projectsError) {
        console.error("Error fetching projects:", projectsError);
      }

      return _tasks || [];
    };

    if (!projectsLoading) {
      getTasks()
        .then((tasks) => {
          setTasks(tasks);
        })
        .catch((error) => {
          console.error("Error fetching tasks:", error);
        });
    }
  }, [!projectsLoading]);

  const handleChange = () => {};
  return (
    <div className="w-[100%] h-[95vh] overflow-y-scroll pt-2">
      <div className="mx-auto px-2">
        <div className="grid items-center gap-2 py-3 grid-cols-12">
          <div className="flex sm:gap-3  overflow-x-scroll lg:overflow-hidden sm:col-span-6 col-span-10 gap-1 ">
            <button
              className={
                activeTab === 1
                  ? "active tab  py-2 shadow hover:shadow-lg  sm:px-4 px-3 bg-[#4e84f1] rounded-lg  text-[#fff]"
                  : "tab text-[#fff]  hover:shadow-lg rounded-lg  hover:bg-[#4e84f1] py-2   hover:text-[#fff] sm:px-4 px-2"
              }
              onClick={() => handleTabClick(1)}
            >
              <p className="cursor-pointer   font-bold text-[12px]   ">
                All Issues
              </p>
            </button>
            <button
              className={
                activeTab === 2
                  ? "active tab  py-2  sm:px-4 px-2 bg-[#4e84f1] rounded-md  text-[#fff]"
                  : "tab text-[#fff]  hover:bg-[#4e84f1] py-2  rounded-lg  hover:text-[#fff] sm:px-4 px-2"
              }
              onClick={() => handleTabClick(2)}
            >
              <p className="cursor-pointer font-bold text-[12px]  hover:bg-[#4e84f1] hover:text-[#fff] ">
                Pending
              </p>
            </button>
            <button
              className={
                activeTab === 3
                  ? "active tab  py-2  sm:px-4 px-2 bg-[#4e84f1] rounded-md  text-[#fff]"
                  : "tab text-[#fff] hover:bg-[#4e84f1] py-2  rounded-lg  hover:text-[#fff] sm:px-4 px-2"
              }
              onClick={() => handleTabClick(3)}
            >
              <p className="cursor-pointer font-bold text-[12px]   ">Done</p>
            </button>
          </div>
          {/* <div className="sm:grid  justify-items-end hidden sm:col-span-6	col-span-2  xl:w-[60%]  lg:w-[70%] md:w-[80%]  w-[60%] ml-auto gap-2 grid-cols-2">
              <div className="flex w-fit  flojustify-end	at-right items-center gap-2 border-[1px] border-[#CFDBD5] rounded-lg px-4 py-2 cursor-pointer">
                <img src={sort} alt="" className="w-[12px] h-[12px]" />
                <p className="font-RobotoFlexRegular sm:block hidden font-[700] text-[12px] text-black">
                  Sort: A-Z
                </p>
              </div>
              <div className="sm:flex w-fit hidden gap-2 border-[1px] border-[#CFDBD5] rounded-lg px-4 py-2 cursor-pointer">
                <img src={option} alt="" className="w-[10%]" />
                <p className="font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  Bulk Actions
                </p>
              </div>
            </div>
            <div className=" sm:hidden   block  col-span-2">
              <div className="border-[2px] py-2  border-[#CFDBD5] rounded">
                <img
                  src={sort}
                  alt=""
                  className="w-[12px] mx-auto sm:hidden block h-[12px]"
                />
              </div>
            </div> */}
        </div>

        {/* Tabs Content */}
        <div className="grid mb-10 lg:mb-2 grid-cols-1">
          {activeTab === 1 && (
            <ul>
              {tasks.map((item: any, index: number) => {
                return (
                  <li key={index} className="mb-2">
                    <div className="grid items-center hover:drop-shadow-sm cursor-pointer bg-white py-2 px-4 rounded-[6px] grid-cols-12 py-5">
                      <div className="sm:col-span-7  col-span-12">
                        <div>
                          <ul className="max-w-md col-span-12 flex gap-2 items-center space-y-1 text-gray-500 list-inside dark:text-gray-400">
                            <li className="sm:block hidden">
                              <Checkbox
                                id="remember"
                                aria-describedby="remember"
                                onChange={handleChange}
                                color="error"
                                className="de_checkbox"
                                sx={{
                                  color: blue,
                                  "&.Mui-checked": {
                                    color: blue,
                                  },
                                  "&:hover": {
                                    padding: "0px",
                                    backgroundColor: "transparent",
                                  },
                                  padding: "0px",
                                  background: "transparent",
                                }}
                              />
                            </li>
                            <li className="hover:drop-shadow-lg flex gap-2 items-center font-bold text-black text-[14px]">
                              {/* <img
                                  src={dropbox}
                                  alt=""
                                  className="w-[32px] "
                                /> */}
                              <div className=" justify-center">
                                <Link
                                  to={PathConstants.projectPages
                                    .replace(":slug", item.slug)
                                    .replace(":page", "issues")}
                                >
                                  {item.name}
                                </Link>
                                <li className="sm:hidden w-[50%] block hover:drop-shadow-lg">
                                  <ProgressBar
                                    completed={60}
                                    maxCompleted={100}
                                    height="5px"
                                    bgColor="#81DE4A"
                                    className="my-2"
                                    labelSize={"0"}
                                  />
                                </li>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      <Footer />
      {/* <BottomBar /> */}
    </div>
  );
};
export default MyIssues;
