import React from "react";
import Bell from "../assets/Bell.svg";
import Light from "../assets/onLight.svg";
import Person from "../assets/person.png";
import sketch from "../assets/sketch.png";
import Images from "../assets/Images.png";
import { ProfileModal } from "./modal/ProfileModal";
import { signOut } from "../helpers/supabase";

export default function Menubar({ user }: { user: any }) {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = React.useState(false);

  return (
    <>
      <div className="bg-[#f5f5fa] border-b-[1px] border-[#CFDBD5] hidden  z-10 lg:block fixed top-0 w-[80%]">
        <div className="grid grid-cols-2 py-5  w-[90%] mx-auto">
          <div className="self-center">
            <p className=" font-RobotoFlexRegular flex items-center gap-2 font-[800] text-[18px] text-black">
              {"{project_name}"}
            </p>
          </div>

          <div className="flex ">
            <div className="flex ml-auto">
              <img src={Light} alt="" className="cursor-pointer" />
              <img src={Bell} alt="" className="cursor-pointer" />
              <img src={Images} alt="" className="h-[30px] self-center" />
            </div>
            <button className="ml-auto  font-RobotoFlexRegular font-[700] text-[12px] text-white bg-[#0052FF] hover:shadow-md py-2 px-8 rounded-lg">
              Create new
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className="bg-[#E9E9F2]  flex justify-between lg:hidden w-[100%]  mx-auto px-5  py-5 fixed z-10 top-0 ">
          <div className="p-3 border-[2px] border-[#CFDBD5] hover:bg-[#0052FF] hover:text-white rounded-lg ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>

          <p className="self-center font-RobotoFlexRegular font-[800] text-[18px] text-black">
            All Projects
          </p>
          <div className="relative" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <div
              
              className="cursor-pointer"
            >
              <img src={Person} alt="Profile" className="w-[40px] h-[40px]" />
            </div>

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-black border border-gray-300 dark:border-gray-700 rounded shadow-lg">
                <button
                  onClick={() => setIsProfileModalOpen(true)}
                  className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  Profile
                </button>
                <button
                  onClick={() => signOut()}
                  className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  Logout
                </button>
              </div>
            )}

            <ProfileModal
              user={user}
              setIsProfileModalOpen={setIsProfileModalOpen}
              isProfileModalOpen={isProfileModalOpen}
            />
          </div>
        </div>
      </div>
    </>
  );
}
