import { useEffect, useState } from "react";
import { useUser } from "../../context/userInfo";

export const PlanDetail = () => {
  const { user, projects } = useUser();
  const workspace = sessionStorage.getItem("workspace");
  const [taskCount, setTaskCount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);

  useEffect(() => {
    if (projects) {
      const projectCount = projects.filter((project: any) => project.owner_id === user.id).length;
      const memberCount = projects.filter((project: any) => project.id === workspace).reduce((acc: any, project: any) => {
        return acc + project.project_members.length;
      }, 0);
      const taskCount = projects.filter((project: any) => project.id === workspace).reduce((acc: any, project: any) => {
        return acc + project.project_tasks.length;
      }, 0);

      setTaskCount(taskCount);
      setMemberCount(memberCount);
      setProjectCount(projectCount);
    }
}, [projects]);

  return (
    <div className="relative max-lg:row-start-2 lg:col-start-1 lg:row-start-2">
      <div className="absolute inset-px bg-bgLight rounded-md dark:text-darkTextActiveColor border-[1px] dark:bg-bgDark dark:border-[#303030] border-[#CFDBD5]"></div>
      <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
        <div className="px-8 pt-8 sm:px-10 sm:pt-10">
          <p className="flex justify-between items-center mt-2 text-lg font-medium tracking-tight text-white max-lg:text-center">
            {user.user_plan?.plan_id?.name} Plan

            {user.user_plan?.plan_id?.plan_id !== 1 && (
              <span className="text-4xl font-bold text-white">
                {user.user_plan?.plan_id?.price}
                <span className="ml-2 text-sm font-medium text-gray-400">/month</span>
              </span>
            )}
          </p>
          <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
            Plan breakdown and usage details.
          </p>
        </div>

        {/* Create list view of features */}
        <div className="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2 overflow-y-scroll mt-5">
          <ul
            role="list"
            className="divide-y divide-gray-100 dark:divide-gray-600 overflow-y-scroll h-full w-full"
          >
            <li className="py-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4"><p className="text-sm/6 text-gray-600">Projects:</p></div>
                <p className="text-sm/6 text-white">{projectCount} / {user.user_plan?.plan_id.max_projects}</p>
              </div>
            </li>

            <li className="py-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4"><p className="text-sm/6 text-gray-600">Tasks Per Project:</p></div>
                <p className="text-sm/6 text-white">{taskCount} / {user.user_plan?.plan_id.max_tasks_per_project}</p>
              </div>
            </li>

            <li className="py-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4"><p className="text-sm/6 text-gray-600">Members:</p></div>
                <p className="text-sm/6 text-white">{memberCount} / {user.user_plan?.plan_id.user_limit}</p>
              </div>
            </li>

            <li className="py-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4"><p className="text-sm/6 text-gray-600">Teams:</p></div>
                <p className="text-sm/6 text-white">{user?.teams?.length} / {user.user_plan?.plan_id.team_limit}</p>
              </div>
            </li>


          </ul>
        </div>
      </div>
      <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
    </div>
  );
};
