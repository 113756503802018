import "./App.scss";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Suspense, lazy, useEffect, useState } from "react";
import Signup from "./pages/Signup";
import Dashboard from "./pages/dashboard/Dashboard";
import Projects from "./pages/projects";
import Inbox from "./pages/inbox";
import Chatscreen from "./pages/chatscreen";
import EmptyKanBanDesk from "./pages/kanban-empty";
import Discussions from "./pages/discussions";
import MyIssuesEmpty from "./pages/myIssuesEmpty";
import MyIssues from "./pages/myIssues";
import CreateWorkspace from "./pages/createProject";
import { KanBanDeskPage } from "./pages/kanban";
import Login from "./pages/login";
import { useWorkspace } from "./context/workspace";
import CRMContacts from "./pages/crm/contacts";
import Sidebar from "./components/sidebar/sidebar";
import Navbar from "./components/navbar";
import { supabase } from "./helpers/supabase";
import ProtectedRoute from "./ProtectedRoute";
import { NotFoundPage } from "./pages/NotFound";
import { RenewPlan } from "./pages/RenewPlan";
import { useUser } from "./context/userInfo";

function SaveLocation() {
  const location = useLocation();

  // save last location to local storage every time the page changes
  useEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "/signup") {
      sessionStorage.setItem("lastLocation", location.pathname);
    }
  }, [location.pathname]);

  return null;
}

function App() {
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { workspaces, loadingWorkspaces } = useWorkspace();
  const savedWorkspace = sessionStorage.getItem("workspace");

  // set dark mode
  useEffect(() => {
    document.body.classList.add("dark");
  }, []);

  // check if user is logged in
  useEffect(() => {
    const checkSession = async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (!session || error) {
        return false;
      }
      return true;
    };

    // check if user is logged in
    checkSession().then((isLoggedIn) => {
      if (!isLoggedIn) {
        if (location.pathname !== "/" && location.pathname !== "/signup") {
          navigate("/");
        }
      } else {
        setIsLoggedIn(true);
      }
    });
  }, []);

  // Check workspaces
  useEffect(() => {
    if (!loadingWorkspaces && workspaces?.length === 0) {
      navigate("/create-workspace");
    } else if (workspaces?.length > 0) {
      // get first workspace slug
      const currentWorkspace =
        workspaces?.length > 0 && savedWorkspace
          ? workspaces?.find(
              (workspace: any) => workspace.id === savedWorkspace
            )
          : workspaces[0];

      if (location.pathname === "/" && currentWorkspace) {
        sessionStorage.setItem("workspace", currentWorkspace.id);
        navigate(`/${currentWorkspace.slug}/issues`);
      }
      if (currentWorkspace) {
        sessionStorage.setItem("workspace", currentWorkspace.id);
        //navigate(`/${currentWorkspace.slug}/overview`);
      }
    }
  }, [isLoggedIn, workspaces, savedWorkspace, !loadingWorkspaces]);

  useEffect(() => {
    // RenewPlan
    if (user.user_plan?.status === "expired") {
      navigate("/renew");
    }
  }, [user]);

  const router = [
    {
      path: "/",
      requiresAuth: false,
      element: <Login />,
    },
    {
      path: "/signup",
      requiresAuth: false,
      element: <Signup />,
    },
    {
      path: "/:slug/overview",
      requiresAuth: true,
      element: <Dashboard />,
    },
    {
      path: "/:slug/contacts",
      requiresAuth: true,
      element: <CRMContacts />,
    },
    /* ======================== */
    /* ==== Workspaces Start === */
    /* ======================== */
    {
      path: "/workspaces",
      requiresAuth: true,
      element: <Projects />,
    },
    {
      path: "/create-workspace",
      requiresAuth: true,
      element: <CreateWorkspace />,
    },
    {
      path: "/:slug/:page",
      requiresAuth: true,
      element: <KanBanDeskPage />,
    },
    /* ======================== */
    /* ==== Workspaces End ===== */
    /* ======================== */
    {
      path: "/inbox",
      requiresAuth: true,
      element: <Inbox />,
    },
    {
      path: "/chat",
      requiresAuth: true,
      element: <Discussions />,
    },
    {
      path: "/chatscreen",
      requiresAuth: true,
      element: <Chatscreen />,
    },
    {
      path: "/empty-kanbandesk",
      requiresAuth: true,
      element: <EmptyKanBanDesk />,
    },
    {
      path: "/my-issues-empty",
      requiresAuth: true,
      element: <MyIssuesEmpty />,
    },
    {
      path: "/renew",
      requiresAuth: true,
      element: <RenewPlan />,
    },
    {
      path: "/oops",
      requiresAuth: false,
      element: <NotFoundPage />,
    },
  ];

  return (
      <>
        {isLoggedIn && (
          <div className="hidden md:block sidebar">
            <Sidebar />
          </div>
        )}

        <div className={isLoggedIn ? "content lg:w-full" : "w-full"}>
          {isLoggedIn && workspaces.length !== 0 && (
            <Navbar
              title={
                workspaces.length > 0 && savedWorkspace
                  ? workspaces?.find(
                      (workspace: any) => workspace.id === savedWorkspace
                    )?.name
                  : null
              }
              hideActions={false}
            />
          )}

          <Routes>
            {router.map((route, index) => {
              // Determine if this route requires authentication
              if (route.requiresAuth) {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <ProtectedRoute>
                        {route.element}
                      </ProtectedRoute>
                    }
                  />
                );
              } else {
                return <Route key={index} path={route.path} element={route.element} />;
              }
            })}
            {/* 404 Error Page */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          {/* <SaveLocation /> */}
        </div>
      </>
  );
}

export default App;
