import React, { useEffect } from "react";
import Person from "../assets/person.png";
import { useLocation } from "react-router-dom";
import Sidebar from "../components/sidebar/sidebar";
import { ProfileModal } from "../components/modal/ProfileModal";
import { signOut } from "../helpers/supabase";
import { ProfileImage } from "./profile/profileImage";
import { getUser } from "../hooks/user";

export default function Navbar({
  title,
  hideActions,
  btnAction,
}: {
  title?: string | null;
  hideActions?: boolean;
  btnAction?: () => void;
}) {
  const { user } = getUser();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const profileMenuItem = ({ title, action } : { title: string, action: () => void } ) => {
    return (
      <button
        onClick={() => action()}
        className="block w-full px-4 py-3 text-[18px] text-gray-700 font-[600] dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
        >
        {title}
      </button>
    );
  };

  const handleMenuItemClick = (action: () => void) => {
    action();
    setIsDropdownOpen(false);
  }

  return (
    <>
      {/* Navbar */}
      <div className="dark:bg-dark bg-[#E9E9F2] border-b-[1px] border-borderColor dark:border-darkBorderColor hidden z-10 lg:block">
        <div className="py-4 px-4 mx-auto flex justify-between">
          <div className="self-center">
            <p className="font-RobotoFlexRegular flex items-center gap-2 font-[800] text-[18px] text-black dark:text-darkTextColor">
              {title}
            </p>
          </div>

          <div className="flex items-center">
            {!hideActions && btnAction && (
              <button
                onClick={btnAction}
                className="ml-auto font-RobotoFlexRegular font-[700] text-[12px] text-white bg-[#0052FF] hover:shadow-md py-2 px-8 rounded-lg"
              >
                Create new
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Navbar */}
      <div className="bg-white dark:bg-dark flex justify-between lg:hidden w-[100%] mx-auto px-5 py-5 fixed top-0 z-50">
        {/* Hamburger Icon for Sidebar Toggle */}
        <div className="p-3 border-[2px] border-[#CFDBD5] hover:bg-[#0052FF] hover:text-white rounded-lg" onClick={toggleSidebar}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <title>menu-8</title>
            <g fill="#ffffff">
              <path d="M15,7H1C0.4,7,0,7.4,0,8s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,7,15,7z"></path>
              <path d="M15,1H1C0.4,1,0,1.4,0,2s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,1,15,1z"></path>
              <path d="M15,13H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,13,15,13z"></path>
            </g>
          </svg>
        </div>

        <p className="self-center font-RobotoFlexRegular font-[800] text-[18px] text-white">
          {title || "All Projects"}
        </p>

        <div className="relative" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <div className="cursor-pointer">
          {user && <ProfileImage user={user} small={false} classes="w-[40px] h-[40px]" />}
          </div>
        </div>
      </div>

      {/* Sidebar */}
      {isSidebarOpen && (
        <>
          <div className={`lg:hidden fixed top-0 left-0 z-50 w-[70%] max-w-[300px] h-full transition-transform transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} bg-white dark:bg-bgDark shadow-lg`}>
            <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
          </div>

          {/* Overlay */}
          <div className="lg:hidden fixed inset-0 z-40 bg-black bg-opacity-50" onClick={toggleSidebar}></div>
        </>
      )}

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute z-40 top-20 mt-2 w-full bg-white dark:bg-dark rounded shadow-lg">
          <div className="text-center ">
          { profileMenuItem({ title: "Profile", action: () => handleMenuItemClick(() => setIsProfileModalOpen(true)) }) }
          { profileMenuItem({ title: "Log Out", action: () => handleMenuItemClick(() => signOut()) }) }
          </div>
          
        </div>
      )}

      <ProfileModal user={user} setIsProfileModalOpen={setIsProfileModalOpen} isProfileModalOpen={isProfileModalOpen} />
    </>
  );
}