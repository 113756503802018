import React from "react";
import Imgs2 from "../assets/Imgs2.png";
import Person from "../assets/person.png";
import Sent from "../assets/sent.png";
import Sent2 from "../assets/sent2.png";
import Ico from "../assets/ico.png";
import Emoji from "../assets/emoji.svg";
import Dir from "../assets/dir.svg";
import Icons from "../assets/icons.svg";
import Icons2 from "../assets/icons2.svg";
import Icons3 from "../assets/Icons3.svg";
import Icons4 from "../assets/Icons4.svg";
import Imgs from "../assets/Imgs.svg";
import Icons5 from "../assets/Icons5.svg";
import Icons6 from "../assets/Icons6.svg";
import { Link } from "react-router-dom";

export default function Chatscreen() {
  return (
    <>
      <div className="fixed lg:static top-0 w-full bg-[#E9E9F2] pb-5 lg:pb-0">
        

        <div className="grid grid-cols-2 lg:grid-cols-12 w-[95%] lg:w-[90%] mx-auto pt-5 xl:pt-10  lg:mt-20">
          <div className="hidden lg:flex  col-span-5 xl:col-span-4  justify-between">
            <div className="flex gap-2 border-[1px] border-[#CFDBD5] rounded-lg px-4 py-2 cursor-pointer">
              <img src={Icons} alt="" />
              <p className="font-RobotoFlexRegular font-[700] text-[12px] text-black">
                Compose
              </p>
            </div>

            <div className="flex gap-3">
              <div className="flex gap-2 border-[1px] border-[#CFDBD5] rounded-lg px-4 py-2 cursor-pointer">
                <img src={Icons2} alt="" />
                <p className="font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  Newest
                </p>
              </div>

              <div className=" border-[1px] border-[#CFDBD5] rounded-lg   p-3 cursor-pointer">
                <img src={Icons3} alt="" />
              </div>

              <div className=" border-[1px] border-[#CFDBD5] rounded-lg  px-4 py-3 cursor-pointer">
                <img src={Icons4} alt="" className="mt-[2px]" />
              </div>
            </div>
          </div>

          <Link to="/inbox" className="lg:col-span-5 xl:col-span-6 flex gap-2 lg:justify-center">
            <img src={Imgs} alt="" />
            <p className="font-[700] text-[14px] text-black font-RobotoFlexRegular self-center">
              {" "}
              Sallie Wade
            </p>
          </Link>

          <div className="lg:col-span-2 xl:col-span-2 flex justify-end gap-3">
            <div className=" border-[1px] border-[#CFDBD5] rounded-lg   p-3 cursor-pointer">
              <img src={Icons5} alt="" />
            </div>

            <div className=" border-[1px] border-[#CFDBD5] rounded-lg  px-4 py-3 cursor-pointer">
              <img src={Icons6} alt="" className="mt-[2px]" />
            </div>
          </div>
        </div>
      </div>

      <div className="block lg:hidden w-[100%] xl:w-[70%]  bg-white    p-5 h-[100vh] overflow-y-scroll mt-20">
        <button className="font-[700] text-[12px] text-[#0052FF] font-RobotoFlexRegular bg-[#CCDCFF] py-2 px-5 hover:shadow-md rounded-full block mx-auto">
          48 Messages
        </button>

        <div className="mt-10">
          <div className="flex justify-end gap-2">
            <div>
              <p className="text-right font-RobotoFlexRegular font-[700] text-[12px] text-black">
                <span className="font-[500]  text-[#5F646D]">8:20 PM</span>{" "}
                Gabriela Pires
              </p>

              <p className="bg-[#0052FF] mt-1 rounded-tl-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-white">
                Freelance Design Tricks
              </p>
            </div>
            <img src={Person} alt="" className="w-[34px] h-[34px]" />
          </div>

          <div className="flex justify-start gap-2 mt-10">
            <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

            <div>
              <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                Gabriela Pires{" "}
                <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
              </p>

              <p className="bg-[#F5F5FA] mt-1 md:w-[60%] rounded-tr-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                Some graphic designers always manage to produce
              </p>

              <div className="flex gap-4 mt-5">
                <img src={Sent} alt="" className="w-[40%]" />
                <img src={Sent2} alt="" className="w-[40%]" />
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-2 mt-10">
            <div>
              <p className="text-right font-RobotoFlexRegular font-[700] text-[12px] text-black">
                <span className="font-[500]  text-[#5F646D]">8:20 PM</span>{" "}
                Gabriela Pires
              </p>

              <p className="bg-[#0052FF] mt-1 rounded-tl-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-white">
                Attending a trade show can be a very effective method
              </p>
            </div>
            <img src={Person} alt="" className="w-[34px] h-[34px]" />
          </div>

          <div className="flex justify-start gap-2 mt-10">
            <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

            <div>
              <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                Gabriela Pires{" "}
                <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
              </p>

              <p className="bg-[#F5F5FA] mt-1 rounded-tr-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                Advertising on a budget part
              </p>
            </div>
          </div>

          <div className="flex justify-start gap-2 mt-10">
            <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

            <div>
              <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                Gabriela Pires{" "}
                <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
              </p>

              <p className="bg-[#F5F5FA]  mt-1 rounded-tr-full rounded-b-full py-4 px-3 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                <img src={Ico} alt="" className="mx-auto" />
              </p>
            </div>
          </div>

          <div className="flex justify-end gap-2 mt-10">
            <div>
              <p className="text-right font-RobotoFlexRegular font-[700] text-[12px] text-black">
                <span className="font-[500]  text-[#5F646D]">8:20 PM</span>{" "}
                Gabriela Pires
              </p>

              <p className="bg-[#0052FF] mt-1 rounded-tl-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-white">
                Freelance Design Tricks
              </p>
            </div>
            <img src={Person} alt="" className="w-[34px] h-[34px]" />
          </div>

          <div className="flex justify-start gap-2 mt-10">
            <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

            <div>
              <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                Gabriela Pires{" "}
                <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
              </p>

              <p className="bg-[#F5F5FA] mt-1  rounded-tr-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                Some graphic designers always manage to produce
              </p>

              <div className="flex gap-4 mt-5">
                <img src={Sent} alt="" className="w-[40%]" />
                <img src={Sent2} alt="" className="w-[40%]" />
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-2 mt-10">
            <div>
              <p className="text-right font-RobotoFlexRegular font-[700] text-[12px] text-black">
                <span className="font-[500]  text-[#5F646D]">8:20 PM</span>{" "}
                Gabriela Pires
              </p>

              <p className="bg-[#0052FF] mt-1 rounded-tl-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-white">
                Attending a trade show can be a very effective method
              </p>
            </div>
            <img src={Person} alt="" className="w-[34px] h-[34px]" />
          </div>

          <div className="flex justify-start gap-2 mt-10">
            <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

            <div>
              <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                Gabriela Pires{" "}
                <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
              </p>

              <p className="bg-[#F5F5FA] mt-1 rounded-tr-full rounded-b-full py-2 px-4 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                Advertising on a budget part
              </p>
            </div>
          </div>

          <div className="flex justify-start gap-2 mt-10">
            <img src={Imgs2} alt="" className="w-[34px] h-[34px]" />

            <div>
              <p className=" font-RobotoFlexRegular font-[700] text-[12px] text-black">
                Gabriela Pires{" "}
                <span className="font-[500]  text-[#5F646D]">8:20 PM </span>{" "}
              </p>

              <p className="bg-[#F5F5FA]  mt-1 rounded-tr-full rounded-b-full py-4 px-3 font-RobotoFlexRegular font-[400] text-[14px] text-black">
                <img src={Ico} alt="" className="mx-auto" />
              </p>
            </div>
          </div>
        </div>

        <div className="mt-10 relative">
          <input
            type="text"
            placeholder="Type to add something"
            className="bg-[#F5F5FA] py-5 px-5 outline-none rounded-lg w-full font-[400] text-[#5F646D] text-[14px] font-RobotoFlexRegular"
          />
          <div className="absolute top-3 left-3">
            <img src={Person} alt="" className="w-[34px] h-[34px] hidden md:block" />
          </div>

          <div className="flex gap-2 absolute top-4 right-4">
            <img src={Emoji} alt="" className="cursor-pointer" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="currentColor"
              className="w-4 h-4 self-center cursor-pointer "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>

            <img src={Dir} alt="" className="cursor-pointer" />
          </div>
        </div>
      </div>
    </>
  );
}
