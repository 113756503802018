import { RealtimeChannel } from "@supabase/supabase-js";
import { supabase } from "./supabase";

interface ISubscription {
  channelName: string;
  eventHandler: any;
}

let currentSubscription: RealtimeChannel | null = null;
let pingInterval: NodeJS.Timeout | null = null;

// Subscribe to changes on a channel with heartbeat
export const subscribeToChanges = ({
  channelName,
  eventHandler
}: ISubscription) => {
  const workspaceId = sessionStorage.getItem("workspace");
  // Unsubscribe from any existing subscription
  if (currentSubscription) {
    currentSubscription.unsubscribe();
    if (pingInterval) {
      clearInterval(pingInterval);
      pingInterval = null;
    }
  }

  const channel = supabase.channel(`${channelName}_${workspaceId}`);

  // Subscribe to the channel
  channel.on(
    "postgres_changes",
    { event: "*", schema: "public", table: channelName },
    eventHandler
  );

  channel.subscribe((status) => {
    if (status === 'SUBSCRIBED') {
      // Start heartbeat once subscription is confirmed
      pingInterval = setInterval(() => {
        channel.send({
          type: 'broadcast',
          event: 'heartbeat',
          payload: { message: 'ping' }
        });
        console.log('Sending heartbeat...');
      }, 30000); // Send heartbeat every 30 seconds
    }
  });

  // Store the subscription
  currentSubscription = channel;

  // Return a cleanup function
  return () => {
    if (currentSubscription) {
      currentSubscription.unsubscribe();
      currentSubscription = null;
    }
    if (pingInterval) {
      clearInterval(pingInterval);
      pingInterval = null;
    }
  };
};
