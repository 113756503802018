import { useEffect, useRef, useState } from "react";
import { supabase } from "../../helpers/supabase";
import { getUser } from "../../hooks/user";

const useWorkspaceApi = () => {
  const { user } = getUser();
  const [workspaces, setWorkspaces] = useState<any>({});
  const [loadingWorkspaces, setLoadingWorkspaces] = useState<boolean>(true);

  const [projects, setProjects] = useState<any>([]);
  const projectsRef = useRef(projects); // Create a ref for projects

  /* ======================= */
  /* ==== Update Refs ==== */
  /* ======================= */
  useEffect(() => {
    projectsRef.current = projects;
  }, [projects]);

  /* ======================= */
  /* ==== Get Workspaces ==== */
  /* ======================= */
  useEffect(() => {
    const getWorkspaces = async () => {
      const { data, error } = await supabase.rpc("fetch_workspaces", {});

      if (error) {
        console.error("Error fetching workspaces: ", error);
        setLoadingWorkspaces(false);
        return;
      }

      setWorkspaces(data);
      setLoadingWorkspaces(false);
      return;
    };

    if (!!user?.id && loadingWorkspaces) {
      getWorkspaces();
    }
  }, [user, loadingWorkspaces]);

  /* ======================= */
  /* ==== WorkspaceData ==== */
  /* ======================= */
  return {
    workspaces: workspaces,
    loadingWorkspaces: loadingWorkspaces,
  };
};
export default useWorkspaceApi;
