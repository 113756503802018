import { useEffect, useState } from "react";
import Plus from "../../assets/plus.svg";
import TaskModel from "../../components/modal/TaskModal";
import styles from "./team.module.scss";
import { supabase, admin_supabase } from "../../helpers/supabase";
import { getUser } from "../../hooks/user";
import { blue } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import {
  addMemberToTeam,
  api,
  createTeam,
  fetchProjectMembers,
  getTeams,
} from "../../helpers/api";
import { ProfileImage } from "../../components/profile/profileImage";
import {
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CRMContacts from "../crm/contacts";
import TableRow from "../../components/table/TableRow";

interface Member {
  user_info: {
    id: string;
    full_name?: string;
    email?: string;
  };
}

interface ClickEvent {
  currentTarget: EventTarget & HTMLButtonElement;
}

const TeamMembers = ({ project_id }: { project_id: string }) => {
  const workspace = sessionStorage.getItem("workspace");
  const { projects, user } = getUser();
  const [memberID, setMemberId] = useState("");
  const [isMemberModal, setIsMemberModal] = useState(false);
  const [members, setMembers] = useState<any[]>([]);
  const [teams, setTeams] = useState<any[]>([]);
  const [groups, setGroups] = useState<any[]>([]);

  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentMember, setCurrentMember] = useState<Member | null>(null);

  const [openDialog, setOpenDialog] = useState(false);
  const open = Boolean(anchorEl);
  const [newGroupName, setNewGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [addingMember, setAddingMember] = useState(false);

  // Get Project Owner
  const project_owner = projects.find((project: any) => {
    return project.id === project_id;
  })?.owner_id;

  const closeModal = () => {
    setIsMemberModal(false);
  };

  const handleInputChanges = (e: any) => {
    const { id, value } = e.target;
    setMemberId(value);
  };

  /* =================== */
  /* API Calls */
  /* =================== */
  const handleMemberFlow = async () => {
    if (user.user_plan.plan_id.user_limit === members.length) {
      return alert("You have reached the maximum number of members");
    }
    // check if member is already in project
    const addMember = await api.addMember({ member_id: memberID, project_id });

    if (addMember.error) {
      alert(addMember.error);
      return;
    }

    if (addMember.success) {
      closeModal();
      window.location.reload();
      return;
    }
  };

  const handleClick = (event: ClickEvent, member: Member) => {
    setAnchorEl(event.currentTarget);
    setCurrentMember(member);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMember(null);
    setSubMenuAnchorEl(null);
    setSubMenuOpen(false);
  };

  /* =================== */
  /* Delete Members */
  /* =================== */
  const handleDeleteMember = async (currentMember: Member | null) => {
    const user_id = currentMember?.user_info.id;
    if (!currentMember) return;

    // Check if the user is the account owner
    if (currentMember?.user_info.id === user.id)
      return alert("You cannot remove account owner");

    const deleteMember = await api.deleteMember({
      id: user_id,
      project_id,
    });

    if (!deleteMember.success) {
      alert(deleteMember.error);
      return;
    }

    setMembers(members.filter((member) => member.user_info.id !== user_id));
    handleClose();
    return;
  };

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleConfirmRemove = () => {
    handleDeleteMember(currentMember);
    handleDialog();
  };

  const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSubMenuAnchorEl(event.currentTarget);
    setSubMenuOpen(true);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
    setSubMenuOpen(false);
  };

  /* =================== */
  // GROUPT MANAGEMENT
  /* =================== */
  const handleGroupManagement = async ({ user, team_id }: any) => {
    await addMemberToTeam({ project_id, team_id, user_id: user.id }).then(
      (data) => {
        if (data?.error) {
          alert(data?.error);
          return;
        }

        setTeams(
          teams.map((group) => {
            if (group.team_id === team_id) {
              group?.members.push({
                user_info: {
                  id: user.id,
                  full_name: user.full_name,
                  profile_color: user.profile_color,
                },
              });
              return { ...group, team_count: group.team_count + 1 };
            }
            return group;
          })
        );
      }
    );
  };

  /* =================== */
  // SEARCH MEMBERS
  /* =================== */
  const handleSearch = (e: any) => {
    const { value } = e.target;
    setSearch(value);
    return;
  };

  /* =================== */
  // CREATE TEAM
  /* =================== */
  const handleCreateTeam = async () => {
    if (user.user_plan.plan_id.team_limit === teams.length) {
      return alert("You have reached the maximum number of teams");
    }
    if (newGroupName.trim() === "") return;
    if (groups.some((group) => group.name === newGroupName))
      return alert("Group already exists");
    if (project_id) {
      await createTeam({ teamName: newGroupName, project_id }).then((data) => {
        if (!data) {
          alert("Error creating team");
          return;
        }

        // update teams state
        const newGroup = {
          team_id: data[0].id,
          team_name: newGroupName,
          team_count: 0,
          members: [],
        };

        setGroups([...groups, newGroup]); // Add the new team to the groups state
        setTeams([...teams, newGroup]); // Add the new team to the teams state
        setNewGroupName("");
        return;
      });
    }
  };

  useEffect(() => {
    // Return null if there is no workspace
    if (!workspace) return;

    // fetch all members of the project
    const fetchMembers = async () => {
      await fetchProjectMembers().then((data) => {
        setMembers(data);
      });
      await getTeams({ project_id: workspace }).then((data) => {
        setTeams(data);
        setGroups(data);
      });

      setIsLoading(false);
    };
    fetchMembers();
  }, [workspace]);

  return (
    <div className="h-full overflow-x-scroll">
      <div className="h-full max-w-full lg:max-w-7xl">
        <div className="h-[calc(100%-5rem)] grid gap-4 lg:grid-cols-4 lg:grid-rows-2 m-2">
          {/* Left Column - Team Members */}
          <div className="relative lg:row-span-3 lg:col-start-1 lg:row-start-1 lg:col-span-2">
            <div className="absolute inset-px bg-bgLight rounded-md dark:text-darkTextActiveColor border-[1px] dark:bg-bgDark dark:border-[#303030] border-[#CFDBD5]"></div>
            <div className="relative flex h-full flex-col">
              <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10 overflow-hidden h-[calc(100%-20px)]">
                <p className="flex gap-2 mt-2 text-lg font-medium tracking-tight text-gray-950 dark:text-white max-lg:text-center">
                  <span>Members</span>{" "}
                  <button
                    hidden={project_owner !== user.id}
                    onClick={() => setAddingMember(!addingMember)}
                    className=" text-white font-bold text-[10px]"
                  >
                    {!addingMember ? (
                      <span className="bg-blue p-2 py-1 rounded-lg">Add</span>
                    ) : (
                      <span className="bg-white/40 p-2 py-1 rounded-lg">
                        &#x00d7;
                      </span>
                    )}
                  </button>
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                  Organize your project by adding members and assigning them to
                  teams, enhancing collaboration and productivity.
                </p>
                {addingMember ? (
                  <>
                    <input
                      className="w-full text-sm text-[#000000] border-2 border-darkBorderColor dark:text-[#FFFFFF] font-bold outline-none focus:outline-none bg-white/10 p-4 mt-2 rounded-lg"
                      type="text"
                      placeholder="Enter Member ID"
                      onChange={handleInputChanges}
                      value={memberID}
                      id="memberID"
                    />
                    <div className="border my-2 border-[#CFDBD5]/10"></div>
                    <button
                      className="bg-blue rounded-md p-2 text-white font-bold text-sm w-full"
                      onClick={() => handleMemberFlow()}
                      disabled={!memberID || memberID.length < 25}
                    >
                      Add Team Member
                    </button>
                  </>
                ) : (
                  <input
                    type="text"
                    placeholder="Search Members"
                    onChange={handleSearch}
                    value={search}
                    className="w-full text-sm text-[#000000] border-2 border-darkBorderColor dark:text-[#FFFFFF] font-bold outline-none focus:outline-none bg-transparent dark:bg-transparent p-4 mt-2 rounded-lg"
                  />
                )}
                {!isLoading &&
                  (members?.length > 0 ? (
                    <ul className="overflow-y-scroll h-[calc(90%-100px)]">
                      <li className="flex items-center gap-5 mb-2 text-2xl text-textColor dark:text-darkTextActiveColor">
                        <button
                          hidden={selectedMembers.length === 0}
                          className="cursor-pointer font-RobotoFlexRegular font-[700] text-[12px] bg-red-500 text-white px-3 rounded-full"
                          onClick={() => handleDeleteMember(currentMember)}
                        >
                          Delete
                        </button>
                      </li>
                      {members
                        .filter(
                          (member) =>
                            member.user_info.full_name
                              ?.toLowerCase()
                              .includes(search.toLowerCase()) ||
                            member.user_info.email
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                        )
                        ?.map((item, index) => {
                          return (
                            <li key={index} className="mb-2">
                              <div className="grid items-center hover:drop-shadow-sm py-5 px-4 rounded-[6px] grid-cols-12 bg-white dark:bg-black/10 border-[1px] border-borderColor dark:border-darkBorderColor">
                                <div className="sm:col-span-10 col-span-11">
                                  <ul className="max-w-md col-span-12 flex gap-2 items-center space-y-1 text-gray-500 list-inside dark:text-gray-400">
                                    <li className="hover:drop-shadow-lg flex gap-5 items-center font-[400] text-[14px] text-textColor dark:text-darkTextActiveColor">
                                      <ProfileImage
                                        user={item?.user_info}
                                        small={false}
                                      />
                                      <div className="justify-center">
                                        {item?.user_info?.full_name}
                                        <div className="text-[#b3b3b3] hover:drop-shadow-lg py-1 rounded-full gap-1 items-center">
                                          {item?.user_info?.email}
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                {project_owner === user.id && (
                                  <div className="sm:col-span-2 col-span-1 flex justify-end">
                                    <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={(event) =>
                                        handleClick(event, item)
                                      }
                                      className="dark:text-darkTextActiveColor"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleClose}
                                      PaperProps={{
                                        style: {
                                          maxHeight: 48 * 4.5,
                                          width: "20ch",
                                        },
                                      }}
                                    >
                                      {teams?.length > 0 && (
                                        <MenuItem
                                          style={{ fontSize: 14 }}
                                          onClick={(event) => {
                                            if (subMenuAnchorEl) {
                                              handleSubMenuClose();
                                            } else {
                                              handleSubMenuOpen(event);
                                            }
                                          }}
                                        >
                                          Teams
                                          <span className="ml-2">
                                            &#9654;
                                          </span>{" "}
                                          {/* Right arrow */}
                                          {teams.length > 0 && (
                                            <Menu
                                              anchorEl={subMenuAnchorEl}
                                              open={subMenuOpen}
                                              onClose={handleSubMenuClose}
                                              anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                              transformOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                              }}
                                              PaperProps={{
                                                style: {
                                                  maxHeight: 48 * 4.5,
                                                  width: "20ch",
                                                },
                                              }}
                                            >
                                              {teams.map((group, idx) => (
                                                <MenuItem
                                                  key={idx}
                                                  onClick={() => {
                                                    if (currentMember)
                                                      handleGroupManagement({
                                                        user: currentMember.user_info,
                                                        team_id: group.team_id,
                                                      });
                                                    handleClose();
                                                  }}
                                                >
                                                  {group.team_name}
                                                </MenuItem>
                                              ))}
                                            </Menu>
                                          )}
                                        </MenuItem>
                                      )}
                                      <MenuItem
                                        onClick={handleDialog}
                                        style={{ color: "red", fontSize: 14 }}
                                        className="text-red-500 text-sm"
                                      >
                                        Remove from Workspace
                                      </MenuItem>
                                    </Menu>
                                  </div>
                                )}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  ) : (
                    <p>No Team Members Yet</p>
                  ))}
              </div>
            </div>
          </div>

          {/* Right Column - Add Member */}
          <div
            className="relative lg:row-span-3 lg:col-start-3 lg:row-start-1 lg:col-span-2"
            /* hidden={project_owner !== user.id} */
          >
            <div className="absolute inset-px bg-bgLight rounded-md dark:text-darkTextActiveColor border-[1px] dark:bg-bgDark dark:border-[#303030] border-[#CFDBD5]"></div>
            <div className="relative flex h-full flex-col rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)] overflow-hidden">
              <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-white max-lg:text-center">
                  Teams
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                  Group your teams into focused units to streamline management
                  and boost collaboration.
                </p>
              </div>

              <div className="flex flex-1 px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2 overflow-y-auto">
                <div className="w-full">
                  <div className="" hidden={project_owner !== user.id}>
                    <input
                      type="text"
                      placeholder="Team Name"
                      value={newGroupName}
                      onChange={(e) => setNewGroupName(e.target.value)}
                      className="w-full text-sm text-[#000000] border-2 border-darkBorderColor dark:text-[#FFFFFF] font-bold outline-none focus:outline-none bg-white/10 p-4 mt-2 rounded-lg"
                    />
                    <div className="border my-2 border-[#CFDBD5]/10"></div>
                    <button
                      onClick={handleCreateTeam}
                      className="bg-blue rounded-md p-2 text-white font-bold text-sm w-full"
                    >
                      Create Team
                    </button>
                  </div>

                  {/* Groups List */}
                  <div className="mt-4">
                    <ul
                      role="list"
                      className="divide-y divide-gray-100 dark:divide-gray-600 overflow-y-auto h-full w-full"
                    >
                      {teams?.map((team, index) => {
                        return (
                          <li key={index} className="py-4">
                            <div className="flex justify-between items-center">
                              <span className="flex justify-between items-center w-full text-md font-bold text-gray-600">
                                {team.team_name}
                                <small className="text-xs bg-blue text-white p-1 rounded-xl w-[25px] h-[25px] flex text-center justify-center">
                                  {team.team_count}
                                </small>
                              </span>
                            </div>
                            {/* Members List */}
                            <ol className="ml-4 mt-2 list-decimal">
                              {team?.members?.map(
                                (member: Member, idx: number) => (
                                  <>
                                    <li
                                      key={idx}
                                      className="text-sm font-normal text-gray-600"
                                    >
                                      <p className="group flex items-center justify-between">
                                        <span>
                                          {member.user_info?.full_name}
                                        </span>
                                        <span className="hidden group-hover:block cursor-pointer">
                                          X
                                        </span>
                                      </p>
                                    </li>
                                  </>
                                )
                              )}
                            </ol>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem] lg:rounded-r-[2rem]"></div>
          </div>
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={handleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Removal"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this member?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmRemove} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TeamMembers;
