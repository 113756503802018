/* eslint-disable react-hooks/rules-of-hooks */
import { useUser } from "../context/userInfo";


export const getUser = () => {
  const { user, projects, projectsLoading, getUserInfo } = useUser();
  
  return {
    user: user,
    projects: projects,
    projectsLoading: projectsLoading,
    getUserInfo: async () => getUserInfo(),
  }
};
