import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import Footer from "../../components/footer";
// import BottomBar from "../../components/bottom-bar";
import TableHeader from '../../components/table/TableHeader';
import TableRow from '../../components/table/TableRow';
import { Modal } from "../../components/modal/Modal";
import { useState } from "react";
import { DeleteModal } from "../../components/modal/DeleteModal";
import Input from "../../components/input/input";

const CRMContacts = () => {
  const [open, setOpen] = useState(false);
  const data = [
    {
      id: 1,
      first_name: "Steven",
      last_name: "Jobs",
      email: "jobs@sailboatui.com",
      phone_number: "1234567890",
      company_id: 1,
      lead_source: "Online Marketing",
      imageUrl:
        "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 2,
      first_name: "Steven",
      last_name: "Jobs",
      email: "jobs@sailboatui.com",
      phone_number: "1234567890",
      company_id: 1,
      lead_source: "Online Marketing",
      imageUrl:
        "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 3,
      first_name: "Steven",
      last_name: "Jobs",
      email: "jobs@sailboatui.com",
      phone_number: "1234567890",
      company_id: 1,
      lead_source: "Online Marketing",
      imageUrl:
        "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  ];
  const btnAction = () => {
    console.log("Create new");
    // handle Modal here
    setOpen(true);
  };

  const deleteContact = (id: number) => {
    window.confirm(`Deleting contact with ID: ${id}`);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log("Form submitted", e);
  };

  return (
    <>
      <main className="flex">
        <div className="lg:w-[80%] ">
          <div className="mb-36 md:mb-28 w-[90%] md:w-full mx-auto overflow-y-scroll">
            <div className="overflow-hidden rounded-lg border border-borderColor dark:border-darkBorderColor shadow-md m-5">
              <table className="w-full border-collapse bg-white dark:bg-dark text-left text-sm text-gray-500">
                <TableHeader />
                <tbody className="divide-y divide-darkBorderColor border-t border-borderColor dark:border-darkBorderColor">
                  {data.map((contact, index) => (
                    <TableRow
                      key={index}
                      data={contact}
                      deleteAction={(id: number) => deleteContact(id)}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <Footer />
        </div>
      </main>

      {/* <DeleteModal open={open} setOpen={setOpen} /> */}

      <Modal open={open} setOpen={setOpen}>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between gap-4 my-5">
            <div className="w-full">
              <label>First Name</label>
              <Input type="text" placeholder="First Name" classes={""} />
            </div>
            <div className="w-full">
              <label>Last Name</label>
              <Input type="text" placeholder="Last Name" classes={""} />
            </div>
          </div>

          <div className="flex justify-between gap-4 my-5">
            <div className="w-full">
              <label>Phone Number</label>
              <Input type="text" placeholder="Phone Number" classes={""} />
            </div>
            <div className="w-full">
              <label>Email</label>
              <Input
                type="email"
                placeholder="Email"
                classes={""}
                id="email"
              />
            </div>
          </div>

          <div className="my-5">
            <div className="w-full">
              <label>More Details</label>
              <Input
                isTextArea={true}
                type="email"
                placeholder="Email"
                classes={""}
                id="email"
              />
            </div>
          </div>

          <button
            type="submit"
            className="w-full text-white font-RobotoFlexRegular bg-[#0052FF] hover:shadow-md py-4 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5  text-center "
          >
            Sign in
          </button>
        </form>
      </Modal>
    </>
  );
};

export default CRMContacts;
