import Navbar from "../components/navbar";
import Sidebar from "../components/sidebar/sidebar";
import Undraw from "../assets/undraw.png";
import Plus from "../assets/plus.svg";
import Footer from "../components/footer";
// import BottomBar from "../components/bottom-bar";
import { Link, useNavigate } from "react-router-dom";
import Input from "../components/input/input";
import { useEffect, useState } from "react";
import Card from "../components/card";
import { createWorkspace } from "../api/supabase";
import { getUser } from "../hooks/user";

const CreateWorkspace = () => {
  const { user } = getUser();
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceUrl, setWorkspaceUrl] = useState("");
  const navigate = useNavigate();

  // update workspaceUrl as user types
  useEffect(() => {
    setWorkspaceUrl(workspaceName.toLowerCase().replace(/\s/g, "-"));
  }, [workspaceName]);

  const handleTextChange = (e: any) => {
    const formattedValue = e.target.value.replace(
      window.location.host + "/",
      ""
    );
    e.target.id === "workspaceName" && setWorkspaceName(formattedValue);
    e.target.id === "workspaceUrl" && setWorkspaceUrl(formattedValue);
  };

  return (
    <main className="flex justify-center">
      <div className="lg:w-[80%]">
        <div className="mt-40 mb-36 md:mb-28 md:w-[50%] w-[90%] mx-auto overflow-y-scroll">
          {/* <img
            src={Undraw}
            alt=""
            className="w-[50%] md:w-[40%] xl:w-[20%] mx-auto"
          /> */}
          <p className="text-textColor dark:text-darkTextActiveColor font-RobotoFlexRegular font-[800] text-[25px] md:text-[40px] text-center mt-5 md:mt-10">
            Create a new Workspace
          </p>
          <p className="text-textColor dark:text-darkTextActiveColor font-RobotoFlexRegular text-[16px] text-center mt-5 mb-8 mx-5">
            Workspaces are shared environments where you can collaborate with
            your team members on projects.
          </p>
          <Card classes="">
            <label htmlFor="remember" className="text-textColor dark:text-darkTextActiveColor font-bold">
              Workspace Name
            </label>
            <Input
              id="workspaceName"
              type="text"
              placeholder=""
              classes="my-2 mb-5"
              predefinedText=""
              handleChange={(e: any) => handleTextChange(e)}
            />

            <label htmlFor="remember" className="text-textColor dark:text-darkTextActiveColor font-bold">
              Workspace URL
            </label>
            <Input
              disabled={true}
              id="workspaceUrl"
              type="text"
              placeholder={workspaceUrl}
              classes="my-2 mb-5"
              predefinedText={"~ project/"}
              handleChange={(value: string) => handleTextChange(value)}
              urlSafe={true}
            />

            <button
              disabled={workspaceName === "" || workspaceUrl === ""}
              className="w-full text-darkTextActiveColor font-RobotoFlexRegular bg-[#0052FF] hover:shadow-md py-4 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5  text-center disabled:bg-taskbgDark  disabled:opacity-1 disabled:cursor-not-allowed"
              onClick={async () => {
                await createWorkspace({ name: workspaceName, slug: workspaceUrl, owner_id: user.id }, navigate);
              }}
            >
              Create Workspace
            </button>
          </Card>
        </div>

        <Footer />
      </div>
      {/* <BottomBar /> */}
    </main>
  );
};
export default CreateWorkspace;
