/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from "../assets/logo.svg";
import imgright from "../assets/Group1.jpg";
import envelop from "../assets/Icon.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { blue } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";

import Mlogo from "../assets/Hidden.svg";

import { supabase } from "../helpers/supabase";
import { useWorkspace } from "../context/workspace";

interface ValuesType {
  email: string;
}

interface OPTValuesType {
  email: string;
  token: string;
}

const Login = () => {
  const { workspaces, loadingWorkspaces } = useWorkspace();
  const savedWorkspace = sessionStorage.getItem("workspace");
  const navigate = useNavigate();
  const [emailUsed, setEmailUsed] = useState("");
  const [step, setStep] = useState(1);
  const lastLocation = localStorage.getItem("lastLocation");
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });
  const OptSchema = Yup.object().shape({
    token: Yup.string().required("Required"),
  });

  // Check if user is already logged in and redirect to dashboard
  useEffect(() => {
    const checkLogin = async () => {
      const { data, error } = await supabase.auth.getSession();
      const { session } = data;
      if (session?.access_token) {
        const currentWorkspace =
          workspaces?.length > 0 && savedWorkspace
            ? workspaces?.find(
                (workspace: any) => workspace.id === savedWorkspace
              ) // Return to last workspace
            : workspaces[0]; // Default to first workspace
        if (currentWorkspace) {
          window.location.href =
            lastLocation || `/${currentWorkspace.slug}/overview`;
          return;
        }

        // Error handling
        if (error) {
          console.log(error);
        }
        return;
      }
    };
    checkLogin();
  });

  return (
    <section className="block items-center h-[100vh] lg:flex overflow-auto pb-40 xl:pb-0">
      <div className="grid grid-cols-1 lg:grid-cols-2 ">
        <div className="bg block lg:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className="w-6 h-6 text-white  self-center mt-10 ml-5 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>

          <img src={Mlogo} alt="" className="mx-auto pt-10" />
        </div>

        <div className="my-auto rounded-t-3xl lg:rounded-t-none bg-light dark:bg-bgDark w-full">
          {step === 1 && (
            <div className="lg:w-[80%] xl:w-[60%] w-[90%] mx-auto pt-0">
              <img src={logo} alt="" className="w-[15%] hidden lg:block" />
              <h2 className="text-textColor dark:text-darkTextColor xl:text-[32px] lg:text-[36px] text-[35px] text-center lg:text-start  font-RobotoFlexRegular font-[800] mt-10">
                Welcome to AtomLaunch
              </h2>
              <p className=" text-[#5F646D] font-RobotoFlexRegular text-[15px] lg:text-[14px] py-2 text-center lg:text-start">
                1. Enter your email address below to receive a secure code.
                <br />
                2. We'll send a code to your email. Simply enter the code on the next page to log in.
              </p>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={LoginSchema}
                onSubmit={async (values: ValuesType) => {
                  const { email } = values;
                  // same shape as initial values
                  const { data, error } = await supabase.auth.signInWithOtp({
                    email: email,
                    //password: password,
                    options: {
                      emailRedirectTo: "http://127.0.0.1:3000",
                    },
                  });

                  if (error) {
                    alert(error.message);
                    return;
                  }

                  if (data) {
                    setEmailUsed(email);
                    setStep(2);
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }) => (
                  <form
                    className="space-y-4 xl:py-10 py-4 md:space-y-6 mt-5 lg:mt-0"
                    onSubmit={handleSubmit}
                  >
                    <div className="">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-bold font-RobotoFlexRegular text-textColor dark:text-darkTextColor"
                      >
                        Email
                      </label>
                      <div className="relative">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          onChange={handleChange}
                          className=" bg-white border border-[#CFDBD5]  text-[#000000] placeholder:text-gray-800 font-[500] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full  p-5 outline-[#0052FF] "
                          placeholder=""
                        />
                        {errors.email && touched && (
                          <div
                            id="feedback"
                            className="text-[12px] text-red-500	"
                          >
                            {errors.email}
                          </div>
                        )}
                        <img
                          src={envelop}
                          alt=""
                          className="absolute top-6 right-4"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="w-full text-white font-RobotoFlexRegular bg-[#0052FF] hover:shadow-md py-4 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5  text-center "
                    >
                      Send Verification Code
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          )}

          {step === 2 && (
            <div className="lg:w-[80%] xl:w-[60%] w-[90%] mx-auto pt-0 pb-5 lg:py-10">
              <img src={logo} alt="" className="w-[15%] hidden lg:block" />
              <h2 className="text-textColor dark:text-darkTextColor xl:text-[42px] lg:text-[36px] text-[35px] text-center lg:text-start  font-RobotoFlexRegular font-[800] mt-10">
                Email Verification
              </h2>
              <p className="text-white">Please check your email for the one-time use token. Enter it below to complete your login.</p>
              <Formik
                initialValues={{
                  email: emailUsed,
                  token: "",
                }}
                validationSchema={OptSchema}
                onSubmit={async (values: OPTValuesType) => {
                  const { token } = values;
                  // same shape as initial values
                  const { data, error } = await supabase.auth.verifyOtp({
                    email: emailUsed,
                    token: token,
                    type: "email",
                  });

                  if (data.session) {
                    const { user } = data.session;
                    if (!user) return;

                    // fetch workspaces
                    const { data: workspaceData, error: workspaceError } =
                      await supabase
                        .from("projects")
                        .select("*")
                        .eq("owner_id", user.id);

                    // Set the workspace and redirect to the Overview
                    if (workspaceData) {
                      const workspace = sessionStorage.getItem("workspace");
                      const currentWorkspace =
                        workspaceData.length > 0
                          ? workspaceData.find((ws: any) => ws.id === workspace) ?? workspaceData[0]
                          : workspaceData[0];

                      if (currentWorkspace) {
                        sessionStorage.setItem(
                          "workspace",
                          currentWorkspace.id
                        );
                        window.location.href = `/${currentWorkspace.slug}/overview`;
                      }


                      // Error handling
                      if (workspaceError) {
                        console.log(workspaceError);
                        return;
                      }
                    }
                  } else if (error) {
                    alert(error.message);
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }) => (
                  <form
                    className="space-y-4 xl:py-10 py-4 md:space-y-6 mt-5 lg:mt-0"
                    onSubmit={handleSubmit}
                  >
                    <div className="">
                      <label
                        htmlFor="token"
                        className="block mb-2 text-sm font-bold font-RobotoFlexRegular text-textColor dark:text-darkTextColor"
                      >
                        Token
                      </label>
                      <div className="relative">
                        <input
                          name="token"
                          id="token"
                          onChange={handleChange}
                          className=" bg-white border border-[#CFDBD5]  text-[#000000] placeholder:text-gray-800 font-[500] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full  p-5 outline-[#0052FF] "
                          placeholder=""
                        />
                        {errors.token && touched && (
                          <div
                            id="feedback"
                            className="text-[12px] text-red-500	"
                          >
                            {errors.token}
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="w-full text-white font-RobotoFlexRegular bg-[#0052FF] hover:shadow-md py-4 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5  text-center "
                    >
                      Verify Code
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          )}
          {/* <p className="text-white font-RobotoFlexRegular text-[14px] text-center">
            New to AtomLaunch? {" "}
            <span className="text-[#0052FF] font-RobotoFlexRegular font-bold">
              <Link to="/signup">Register now</Link>
            </span>
          </p> */}
        </div>

        <div className="lg:block hidden">
          <img src={imgright} className="rounded-l-xl bg-black border-t-4 border-l-4 border-b-4 border-white" alt="" />
        </div>
      </div>
    </section>
  );
};
export default Login;
