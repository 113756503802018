import React from "react";
import Sidebar from "../components/sidebar/sidebar";
import Navbar from "../components/navbar";
import Icons from "../assets/icons.svg";
import Icons2 from "../assets/icons2.svg";
import Icons3 from "../assets/Icons3.svg";
import Icons4 from "../assets/Icons4.svg";
import Imgs from "../assets/Imgs.svg";
import Icons5 from "../assets/Icons5.svg";
import Icons6 from "../assets/Icons6.svg";
import Chat from "../components/chat";
import Footer from "../components/footer";
// import BottomBar from "../components/bottom-bar";
import { Link } from "react-router-dom";

export default function Inbox() {
  return (
    <>
      <main className="flex justify-center">
        <div className="w-full lg:w-[80%]">
          <div className="fixed lg:static top-0 w-full bg-[#f5f5fa] pb-5 lg:pb-0">
            <div className="flex lg:hidden  col-span-5 xl:col-span-4  justify-between w-[95%] mx-auto pt-5">
              <Link to="/discussions" className=" border-[1px] border-[#CFDBD5] rounded-lg  px-4 py-3 cursor-pointer">
                <img src={Icons4} alt="" className="mt-[2px]" />
              </Link>

              <div className="flex gap-3">
                <div className="flex gap-2 border-[1px] border-[#CFDBD5] rounded-lg px-4 py-2 cursor-pointer">
                  <img src={Icons2} alt="" />
                </div>

                <div className=" border-[1px] border-[#CFDBD5] rounded-lg   p-3 cursor-pointer">
                  <img src={Icons3} alt="" />
                </div>
                <div className="flex gap-2 border-[1px] border-[#CFDBD5] rounded-lg px-4 py-2 cursor-pointer">
                  <img src={Icons} alt="" />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 lg:grid-cols-12 w-[95%] lg:w-[90%] mx-auto pt-5 xl:pt-10  lg:mt-20">
              <div className="hidden lg:flex  col-span-5 xl:col-span-4  justify-between">
                <div className="flex gap-2 border-[1px] border-[#CFDBD5] rounded-lg px-4 py-2 cursor-pointer">
                  <img src={Icons} alt="" />
                  <p className="font-RobotoFlexRegular font-[700] text-[12px] text-black">
                    Compose
                  </p>
                </div>

                <div className="flex gap-3">
                  <div className="flex gap-2 border-[1px] border-[#CFDBD5] rounded-lg px-4 py-2 cursor-pointer">
                    <img src={Icons2} alt="" />
                    <p className="font-RobotoFlexRegular font-[700] text-[12px] text-black">
                      Newest
                    </p>
                  </div>

                  <div className=" border-[1px] border-[#CFDBD5] rounded-lg   p-3 cursor-pointer">
                    <img src={Icons3} alt="" />
                  </div>

                  <div className=" border-[1px] border-[#CFDBD5] rounded-lg  px-4 py-3 cursor-pointer">
                    <img src={Icons4} alt="" className="mt-[2px]" />
                  </div>
                </div>
              </div>

              <div className="lg:col-span-5 xl:col-span-6 flex gap-2 lg:justify-center">
                <img src={Imgs} alt="" />
                <p className="font-[700] text-[14px] text-black font-RobotoFlexRegular self-center">
                  {" "}
                  Sallie Wade
                </p>
              </div>

              <div className="lg:col-span-2 xl:col-span-2 flex justify-end gap-3">
                <div className=" border-[1px] border-[#CFDBD5] rounded-lg   p-3 cursor-pointer">
                  <img src={Icons5} alt="" />
                </div>

                <div className=" border-[1px] border-[#CFDBD5] rounded-lg  px-4 py-3 cursor-pointer">
                  <img src={Icons6} alt="" className="mt-[2px]" />
                </div>
              </div>
            </div>
          </div>
          <Chat />

          <Footer />
        </div>
      </main>
    </>
  );
}
