import { useNavigate } from "react-router-dom";
import { useWorkspace } from "../context/workspace";
import { useUser } from "../context/userInfo";
import { useEffect } from "react";

export const RenewPlan = () => {
  const navigate = useNavigate();
  const { workspaces } = useWorkspace();
  const { user } = useUser();
  const workspace = sessionStorage.getItem("workspace");

  useEffect(() => {
    if (
      user.user_plan?.status === "active" ||
      user.user_plan?.plan_id?.plan_id === 1
    ) {
      workspaces
        .filter((project: any) => project.id === workspace)
        .map((project: any) => {
          navigate(`/${project.slug}/issues`);
          return;
        });

      navigate(`/${workspaces[0].slug}/issues`);
      return;
    }
  }, [workspaces]);

  // Use tailwind to create a NICE NICE Nice 404 page
  return (
    <div className="flex justify-center">
      <div className="lg:w-[80%]">
        <div className="mt-40 mb-36 md:mb-28 md:w-[50%] w-[90%] mx-auto overflow-y-scroll">
          <p className="text-textColor dark:text-darkTextActiveColor font-RobotoFlexRegular font-[800] text-[22px] md:text-[50px] text-center mt-5 md:mt-10">
            Account Issue
          </p>
          <p className="text-textColor dark:text-darkTextActiveColor font-RobotoFlexRegular text-[16px] text-center mt-5 mb-8 mx-5">
            There was a problem billing your account. Please update your billing
            information to avoid interruptions.
          </p>

          <div className="flex justify-center gap-2 mb-5">
            <button className="w-full text-darkTextActiveColor font-RobotoFlexRegular bg-[#0052FF] hover:shadow-md py-4 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5  text-center disabled:bg-taskbgDark  disabled:opacity-1 disabled:cursor-not-allowed">
              Renew Plan
            </button>
            <button className="w-full text-darkTextActiveColor font-RobotoFlexRegular bg-red-500 hover:shadow-md py-4 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5  text-center disabled:bg-taskbgDark  disabled:opacity-1 disabled:cursor-not-allowed">
              Cancel
            </button>
          </div>

          <hr className="border-darkBorderColor" />
          <p className="text-textColor dark:text-darkTextActiveColor/50 font-RobotoFlexRegular text-xs text-center mt-5 mb-8 mx-5">
            Your plan will be converted to a free plan if you do not renew
            within 7 days.
          </p>
        </div>
      </div>
    </div>
  );
};
