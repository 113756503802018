import { useEffect, useState } from "react";
import KanBanDesk from "./kanban";
import sort from "../../assets/sort.png";
import Sidebar from "../../components/sidebar/sidebar";
import Navbar from "../../components/navbar";
import { getUser } from "../../hooks/user";
import { Link, useLocation } from "react-router-dom";
import Team from "../team/team";
import PathConstants from "../../pathConstants";
import { useWorkspace } from "../../context/workspace";
import Footer from "../../components/footer";
import MyIssues from "../myIssues";

export const KanBanDeskPage = () => {
  const location = useLocation();
  const currentWorkspace = sessionStorage.getItem("workspace");
  const { workspaces } = useWorkspace();
  const { user, projects, projectsLoading } = getUser();
  const [workspaceName, setWorkspaceName] = useState("");
  const pathName = location.pathname;

  useEffect(() => {
    if (workspaces?.length > 0 && currentWorkspace) {
      const workspace = workspaces.find(
        (workspace: any) => workspace.id === currentWorkspace
      );
      if (workspace) {
        setWorkspaceName(workspace.name);
      }
    }
  }, [workspaces, currentWorkspace]);

  // get project id
  const project_id = projects.find((project: any) => {
    return project.slug === pathName.split("/")[1];
  })?.id;
  const lastPathName = pathName.split("/").pop();

  return (
    <>
        <div className="w-full h-full">
          {/* Kanban Desk */}
          {/* {lastPathName === "updates" && <Updates />} */}
          {lastPathName === "issues" && <KanBanDesk />}
          {lastPathName === "team" && <Team project_id={project_id} />}
          {lastPathName === "assigned" && <MyIssues />}
          {/* {lastPathName === "settings" && <Settings />} */}
          {/* {lastPathName === "issues" && <Issues />} */}
          {/* <Footer /> */}
        </div>
    </>
  );
};
